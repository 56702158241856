import { routes } from "./routes";
import { react2angular } from "react2angular";
import ProjectService from "./service.js";

import ProjectsListController from "./list/list.controller";
import ProjectsOwnController from "./own/own.controller";
import ProjectsInsertController from "./insert/insert.controller";
import ProjectsDetailsController from "./details/details.controller.js";
import ProjectsUpdateController from "./update/update.controller.js";

import ProjectsList from "./list/listView.tsx";
import ProjectsInsert from "./insert/insertView.tsx";
import ProjectsOwn from "./own/ownView.tsx";
import ProjectsDetails from "./details/detailsView.tsx";
import ProjectsUpdate from "./update/UpdateView.tsx";

export default angular.module("app.projects", [])
  .config(routes)
  .component("projectsList", react2angular(ProjectsList, [], ["$state", "ProjectService", "$translate"]))
  .component("projectsInsert", react2angular(ProjectsInsert, [], ["$state", "ProjectService", "$translate"]))
  .component("projectsOwn", react2angular(ProjectsOwn, [], ["$state", "ProjectService", "$translate"]))
  .component("projectsDetails", react2angular(ProjectsDetails, [], ["$state", "ProjectService", "$translate"]))
  .component("projectsUpdate", react2angular(ProjectsUpdate, [], ["$state", "ProjectService", "$translate"]))
  .controller('ProjectsListController', ProjectsListController)
  .controller('ProjectsInsertController', ProjectsInsertController)
  .controller('ProjectsOwnController', ProjectsOwnController)
  .controller('ProjectsDetailsController', ProjectsDetailsController)
  .controller('ProjectsUpdateController', ProjectsUpdateController)
  .service("ProjectService", ProjectService)
  .name;
