export default class LayoutController {
  constructor(
    $scope,
    $window,
    LoopBackAuth,
    $rootScope,
    AuthenticationService,
    AuthorizationService,
    $state,
    $translate,
    UIService
  ) {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "pt");
      $translate.use("pt");
    }

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );

    this.$translate = $translate;

    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.$state = $state;
    this.$window = $window;
    this.UI = UIService;
    this.title = $rootScope.title;
    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.status = data;
      $rootScope.status = data;
    });

    this.menuItems = [];

    this.user = AuthenticationService.getUser();

    this.isAuthenticated = () => {
      AuthenticationService.isAuthenticated();
    };

    this.parseMenu();
  }

  accountManagement = () => {
    this.UI.showConfirm(this.$translate.instant("TEXT_LEAVE")).then((r) => {
      if (r) {
        if (this.user.email.includes("student")) {
          // Go to inforestudante
          this.$window.location.href = "https://inforestudante.uc.pt/";
        } else {
          // Go to UCS
          this.$window.location.href =
            "https://myaccount.deec.uc.pt/univention/self-service/#profiledata";
        }
      }
    });
  };

  changeLang = () => {
    if (this.lang === "pt") {
      localStorage.setItem("lang", "en");
      this.$translate.use("en");
    } else {
      localStorage.setItem("lang", "pt");
      this.$translate.use("pt");
    }
  };

  logout = () => {
    this.AuthenticationService.logout();
  };

  parseMenu = () => {
    const jsonMenu = require("../navigation.json");
    // Getting own of all states, so that role will be loaded from states...
    let states = this.$state.get();
    states = _.filter(states, (s) => {
      // Ignore abstract routes and placeholders
      return s.hasOwnProperty("template") && s.hasOwnProperty("controller");
    });

    // Iterate the sidebar options through the states, to get the required role for it
    let menu = [];
    jsonMenu.forEach((section) => {
      if (section.hasOwnProperty("sections")) {
        let k = [];
        section.sections.forEach((o) => {
          if (o.hasOwnProperty("link")) {
            if (o.hasOwnProperty("memberOf")) {
              if (this.AuthorizationService.canPerform(o.memberOf) || this.AuthorizationService.isWhitelisted(o.state, this.user.email)) {
                if (o.hasOwnProperty("params")) {
                  o.state += `(${JSON.stringify(o.params)})`;
                }
                k.push(o);
              }
            } else {
              if (o.hasOwnProperty("params")) {
                o.state += `(${JSON.stringify(o.params)})`;
              }
              k.push(o);
            }
          } else {
            let s = _.find(states, {
              name: o.state,
            });
            if (angular.isDefined(s)) {
              if (s.hasOwnProperty("memberOf")) {
                if (this.AuthorizationService.canPerform(s.memberOf) || this.AuthorizationService.isWhitelisted(o.state, this.user.email)) {
                  if (o.hasOwnProperty("params")) {
                    o.state += `(${JSON.stringify(o.params)})`;
                  }
                  k.push(o);
                }
              } else {
                if (o.hasOwnProperty("params")) {
                  o.state += `(${JSON.stringify(o.params)})`;
                }
                k.push(o);
              }
            } else {
              // Do nothing... :)
            }
          }
        });
        if (k.length > 0) {
          section.sections = k;
          menu.push(section);
        }
      } else {
        let s = _.find(states, {
          name: section.state,
        });
        if (angular.isDefined(s)) {
          if (s.hasOwnProperty("memberOf")) {
            if (Array.isArray(s.memberOf)) {
              let r = this.AuthenticationService.isWhitelisted(s.state, this.user.email);
              if (this.AuthorizationService.belongsTo(s.memberOf) || this.AuthorizationService.isWhitelisted(s.state, this.user.email)) {
                if (section.hasOwnProperty('params')) {
                  section.state += `(${JSON.stringify(section.params)})`;
                }
                menu.push(section);
              }
            } else {
              if (this.AuthorizationService.canPerform(s.memberOf) || this.AuthorizationService.isWhitelisted(s.state, this.user.email)) {
                if (section.hasOwnProperty("params")) {
                  section.state += `(${JSON.stringify(section.params)})`;
                }
                menu.push(section);
              }
            }
          } else {
            if (section.hasOwnProperty("params")) {
              section.state += `(${JSON.stringify(section.params)})`;
            }
            menu.push(section);
          }
        } else {
          // Do nothing... :)
        }
      }
    });
    this.menuItems = menu;
  };
}

LayoutController.$inject = [
  "$scope",
  "$window",
  "LoopBackAuth",
  "$rootScope",
  "AuthenticationService",
  "AuthorizationService",
  "$state",
  "$translate",
  "UIService",
];
