export function routes($stateProvider) {
    $stateProvider
      .state('app.news', {
        url: '/news',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('app.news.list', {
        url: '/list',
        template: require('./list/view.html'),
        controller: 'NewsListController',
        controllerAs: 'vm'
      })
      .state('app.news.create', {
        url: '/insert',
        template: require('./create/view.html'),
        controller: 'NewsInsertController',
        controllerAs: 'vm',
        memberOf: ['cn=DEEC_My_News,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt']
      })
      .state('app.news.own', {
        url: '/own',
        template: require('./own/view.html'),
        controller: 'NewsOwnController',
        controllerAs: 'vm',
        memberOf: ['cn=DEEC_My_News,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt']
      })
      .state('app.news.edit', {
        url: '/:id/edit',
        template: require('./edit/view.html'),
        controller: 'NewsEditController',
        controllerAs: 'vm',
        memberOf: ['cn=DEEC_My_News,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt']
      })
      .state('app.news.details', {
        url: '/:id',
      template: require('./details/view.html'),
      controller: 'NewsDetailscontroller',
      controllerAs: 'vm',
      memberOf: ['cn=DEEC_My_News,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt']
      })
    ;
  }

  routes.$inject = ['$stateProvider'];
