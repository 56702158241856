import {
  routes
} from './routes';
import JobService from "./service";
import JobsListcontroller from "./list/controller";
import JobsDetailscontroller from "./details/controller";
import JobInsertController from "./insert/controller";
import JobsOwnController from "./own/controller";
import JobsEditController from "./edit/controller";

export default angular.module('app.jobs', [])
  .config(routes)
  .service('JobService', JobService)
  .controller('JobsListcontroller', JobsListcontroller)
  .controller('JobsDetailscontroller', JobsDetailscontroller)
  .controller('JobInsertController', JobInsertController)
  .controller('JobsOwnController', JobsOwnController)
  .controller('JobsEditController', JobsEditController)
  .name;
