export default class AdminAccountInsertController {
  constructor(
    $state,
    $scope,
    $translate,
    UIService,
    AdminAccountService,
    FileUploader
  ) {
    this.$state = $state;
    this.$translate = $translate;
    this.UI = UIService;
    this.Account = AdminAccountService;
    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );
    // Setup uploader
    this.uploader = new FileUploader({
      url: "/",
      queueLimit: 1,
    });
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name == "queueLimit") {
        $scope.jsonUploader.clearQueue();
        $scope.jsonUploader.addToQueue(item);
      }
    };
    this.uploader.onAfterAddingFile = (file) => {
      let fileReader = new FileReader();
      fileReader.onload = (ev) => {
        let base64 = ev.target.result.split(",")[1];
        this.data.picture = base64;
      };
      fileReader.readAsDataURL(file._file);
    };
    // Load data
    this.loadData();
  }

  select = (choice) => {
    if (this.data.domain) {
      if (!this.data.domain.includes(choice.selected)) {
        this.data.occupation = null;
      }
    }
    this.data.domain = choice.selected;
    let data = [
      {
        domain: "deec.uc.pt",
        name: "Aluno",
      },
      {
        domain: "deec.uc.pt",
        name: "Docente",
      },
      {
        domain: "deec.uc.pt",
        name: "Colaborador",
      },
      {
        domain: "isr.uc.pt",
        name: "Investigador",
      },
      {
        domain: "isr.uc.pt",
        name: "Colaborador",
      },
      {
        domain: "co.it.pt",
        name: "Investigador",
      },
      {
        domain: "co.it.pt",
        name: "Colaborador",
      },
      {
        domain: "inescc.pt",
        name: "Investigador",
      },
      {
        domain: "inescc.pt",
        name: "Colaborador",
      },
    ];
    this.list = data.filter((r) => r.domain.includes(choice.selected));
    this.list = this.list.map((r) => {
      return r.name;
    });
  };

  requiresExtraField = () => {
    return (
      this.data &&
      this.data.occupation &&
      (this.data.occupation.includes("Investigador") ||
        this.data.occupation.includes("Aluno"))
    );
  };

  isStaffDEEC = () => {
    return (
      this.data &&
      this.data.domain &&
      this.data.domain.includes("deec.uc.pt") &&
      (this.data.occupation.includes("Docente") ||
        this.data.occupation.includes("Colaborador"))
    );
  };

  loadData = () => {
    this.Account.getDomains().then((r) => {
      this.domains = r;

      this.data = {
        expirationDate: moment().add(1, "y"),
        state: 0,
        employeeNumber: "",
      };

      this.loaded = true;
    });
  };

  save = () => {
    if (this.userAddForm.$invalid) {
      this.UI.addToast(this.$translate.instant("TEXT_REQUIRED_FIELDS"));
    } else {
      this.data.created = moment();
      this.data.displayName = `${this.data.firstName} ${this.data.lastName}`;
      this.Account.save(this.data)
        .then((r) => {
          this.UI.addToast(this.$translate.instant("TEXT_ACCOUNT_SUCCESS"));
          this.$state.go("app.admin.account.list");
        })
        .catch((e) => {
          this.UI.addToast(this.$translate.instant("TEXT_ACCOUNT_FAILED"));
        });
    }
  };
}

AdminAccountInsertController.$inject = [
  "$state",
  "$scope",
  "$translate",
  "UIService",
  "AdminAccountService",
  "FileUploader",
];
