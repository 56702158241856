import { routes } from "./routes";
import { react2angular } from "react2angular";
import ChargeService from "./service.js";

import ChargesListController from "./list/list.controller";
import ChargesInsertController from "./insert/insert.controller";
import ChargesUpdateController from "./update/update.controller.js";

import ChargesList from "./list/listView.tsx";
import ChargesInsert from "./insert/insertView.tsx";
import ChargesUpdate from "./update/UpdateView.tsx";

export default angular.module("app.charges", [])
  .config(routes)
  .component("chargesList", react2angular(ChargesList, [], ["$state", "ChargeService", "$translate"]))
  .component("chargesInsert", react2angular(ChargesInsert, [], ["$state", "ChargeService", "$translate"]))
  .component("chargesUpdate", react2angular(ChargesUpdate, [], ["$state", "ChargeService", "$translate"]))
  .controller('ChargesListController', ChargesListController)
  .controller('ChargesInsertController', ChargesInsertController)
  .controller('ChargesUpdateController', ChargesUpdateController)
  .service("ChargeService", ChargeService)
  .name;
