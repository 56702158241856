import moment from 'moment';

export default class MediaController {
  constructor($state, $scope, $translate, FileUploader, UIService, MediaService, AuthenticationService) {
    this.$state = $state;
    this.$translate = $translate;
    // Setup upload
    let user = AuthenticationService.getUser();
    if (!user) {
      $state.refresh();
    }
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
    const crypt = (salt, text) => {
      const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
      const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
      const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

      return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
    };
    this.uploader = new FileUploader({
      url: `/api/assets/media/${crypt("wowsuchkey", user.email)}`,
      queueLimit: 10
    });

    this.uploader.onSuccessItem = (item, response) => {

      // Save in DB
      this.data = {
        size: response.result.size,
        name: response.result.name,
        originalName: response.result.originalName,
        date: moment()
      }
      this.Media.save(this.data);
      this.loadData();
    }

    this.UI = UIService;
    this.Media = MediaService;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.data = [];
    this.Media.my().then(r => {
      this.total = r.total;
      this.data = r.data;
      this.loaded = true;
    });
  }

  upload = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./upload.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.uploader = this.uploader;

        $scope.cancel = () => {
          $scope.$dismiss();
        }

        $scope.ok = () => {
          $scope.$close();
        }
      }]
    }).then(r => {
    });
  }

  download = d => {
    return `/api/v2/media/${d.id}`;
  }
}

MediaController.$inject = ['$state', "$scope", "$translate", "FileUploader", "UIService", "MediaService", "AuthenticationService"];
