import {routes} from './routes';
import { react2angular } from "react2angular";
import { ErasmusExplorer } from "./view/explorer.jsx";
import MobilityController from "./view/controller";

export default angular.module('app.mobility', [])
  .config(routes)
  .component("erasmusExplorer", react2angular(ErasmusExplorer, ["data"], []))
  .controller('MobilityController', MobilityController)
  .name;
