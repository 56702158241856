export default class ProfileController {
  constructor($scope, AuthenticationService, UIService, bio, $translate) {
    this.bio = bio;
    this.UI = UIService;
    this.$translate = $translate;
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
    this.user = AuthenticationService.getUser();
    this.loadData();
  }

  loadData = () => {
    this.bio.find({
      filter: {
        where: {
          userId: this.user.id
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        this.data = r[0];
      }

    })
      .catch(e => {
        this.UI.addToast(this.$translate.instant('TEXT_SERVER_ERROR'));
      });
  }

  save = () => {
    this.data.userId = this.user.id;
    if (isNaN(this.user.id)) {
      this.UI.addToast("Contacte o GRI por causa do seu identificador UCS");
      return;
    }
    this.data.biography = this.data.biography || { pt: "", en: "" };
    this.bio.upsert(this.data).$promise.then(r => {
      this.data = r;
      this.UI.addToast(this.$translate.instant('TEXT_DATA_SAVED'));
    }).catch(e => {
      this.UI.addToast(this.$translate.instant('TEXT_SERVER_ERROR'));
    });
  }

}

ProfileController.$inject = ['$scope', 'AuthenticationService', 'UIService', 'Bio', '$translate'];
