export function routes($stateProvider) {
  $stateProvider
    .state('app.evaluations', {
      url: '/evaluations',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.evaluations.insert', {
      url: '/insert',
      template: require('./insert/view.html'),
      controller: 'EvaluationsInsertController',
      controllerAs: 'vm',
      //memberOf: "cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt"
    })
    .state('app.evaluations.own', {
      url: '/own',
      template: require('./insert/view.html'),
      controller: 'EvaluationsInsertController',
      controllerAs: 'vm',
      //memberOf: "cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt"
    })
    .state('app.evaluations.list', {
      url: '/list',
      template: require('./list/view.html'),
      controller: 'EvaluationsInsertController',
      controllerAs: 'vm',
      //memberOf: "cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt"
    })
  ;
}

routes.$inject = ['$stateProvider'];
