export default class AdminAccountService {
  constructor($q, $http, Account) {
    this.$q = $q;
    this.$http = $http;
    this.Account = Account;
    this.domains = [];
  }

  getDomains = () => {
    let defer = this.$q.defer();
    if (this.domains.length > 0) {
      defer.resolve(this.domains);
    } else {
      this.$http
        .get("/api/ldap/servers")
        .then((r) => {
          let aux = [];
          r.data = r.data || [];
          r.data.forEach((a) => {
            aux.push(a.name);
          });
          this.domains = aux;
          defer.resolve(aux);
        })
        .catch((e) => defer.reject(e));
    }
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    this.Account.count({
      where: filter.where,
    })
      .$promise.then((r) => {
        this.Account.find({
          filter: filter,
        })
          .$promise.then((list) => {
            defer.resolve({
              total: r.count,
              data: list,
            });
          })
          .catch((err) => {
            defer.reject(err);
          });
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    this.Account.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

AdminAccountService.$inject = ["$q", "$http", "Account"];
