import { routes } from "./routes";
import MediaService from "./service";
import MediaController from "./own/controller";
import ExplorerController from "./material/controller";
import { react2angular } from "react2angular";
import { MediaMaterial } from "./material/explorer.jsx";

export default angular
  .module("app.media", [])
  .component("mediaMaterial", react2angular(MediaMaterial, ["data"], []))
  .config(routes)
  .service("MediaService", MediaService)
  .controller("MediaController", MediaController)
  .controller("ExplorerController", ExplorerController)
  .filter("bytes", function () {
    return function (bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
      if (typeof precision === "undefined") precision = 1;
      var units = ["bytes", "kB", "MB", "GB", "TB", "PB"],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + " " + units[number];
    };
  }).name;
