export default class NewsOwnController {
    constructor($state, $scope, UIService, NewsService) {
        this.NewsService = NewsService;
        $scope.$watch(() => {
            return localStorage.getItem("lang")
        }, (val) => {
            this.lang = val;
        }, true);
        this.loadData();
    }

    //Load News
    loadData = () => {        
        //this.loaded = false;
        this.newsLists = [];
        let filter = {filter: {order: 'date DESC'}};
        this.NewsService.listNews(filter).then(list => {
            this.newsLists = list;
            this.loaded = true;
        });
    }
}

NewsOwnController.$inject = ['$state', '$scope', 'UIService', 'NewsService'];