export default class MediaService {
  constructor(Asset, Media, $q, AuthenticationService, Recycle, AuthorizationService) {
    this.Auth = AuthenticationService;
    this.Authorize = AuthorizationService;
    this.Recycle = Recycle;
    this.Media = Media;
    this.Asset = Asset;
    this.$q = $q;
  }

  my = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Media.find({
      filter: {
        where: {
          userId: user.email,
        },
        order: "id DESC",
      },
    })
      .$promise.then((r) => {
        defer.resolve({
          total: r.length,
          data: r,
        });
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    this.Media.count({
      where: filter.where,
    })
      .$promise.then((r) => {
        this.Media.find({
          filter: filter,
        })
          .$promise.then((list) => {
            defer.resolve({
              total: r.count,
              data: list,
            });
          })
          .catch((err) => {
            defer.reject(err);
          });
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Media.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  isOwner = (data) => {
    let user = this.Auth.getUser();
    if (!user) {
      return false;
    }
    if (!data.inserted) {
      return false;
    }
    return data.userId === user.email;
  };

  save = (data) => {
    let user = this.Auth.getUser();
    let defer = this.$q.defer();
    data.userId = data.userId || user.email;
    this.Media.upsert(data)
      .$promise.then((data) => {
        defer.resolve(data);
      })
      .catch((e) => {
        defer.reject(["Não foi possível guardar a proposta de emprego"]);
      });
    return defer.promise;
  };

  explorer = () => {
    let defer = this.$q.defer();
    this.Asset.fileExplorer()
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  erasmus = () => {
    let defer = this.$q.defer();
    this.Asset.erasmusExplorer()
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  delete = (media) => {
    let defer = this.$q.defer();
    let u = this.Auth.getUser();
    this.Media.deleteById({
      id: media.id,
    })
      .$promise.then(() => {
        this.Recycle.create({
          model: "Media",
          userId: u.email,
          object: JSON.stringify(media),
        })
          .$promise.then(() => {
            defer.resolve();
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

MediaService.$inject = ["Asset", "Media", "$q", "AuthenticationService", "Recycle", "AuthorizationService"];
