export function routes($stateProvider) {
  $stateProvider
    .state('auth.redirecting', {
      url: '/redirect?access-token&user-id',
      controller: 'RedirectController',
      controllerAs: 'vm',
      template: require('./redirect/redirect.view.html')
    })
    .state('auth.login', {
      url: '/login',
      title: 'Iniciar Sessão',
      template: require('./login/login.view.html'),
      controller: 'LoginController',
      controllerAs: 'vm',
    })
}

export function routing($urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/');
}

routing.$inject  = ['$urlRouterProvider', '$locationProvider'];
routes.$inject = ['$stateProvider'];
