export function routes($stateProvider) {
  $stateProvider
    .state('public', {
      url: '/public',
      abstract: true,
      template: require('./public.view.html')
    })
    .state('public.dissertations', {
      url: '/dissertations',
      template: require('./dissertations/view.html'),
      controller: 'PublicDissertationController',
      controllerAs: 'vm'
    })
    .state('public.dissertation-details', {
      url: '/dissertations/{id}',
      template: require('./dissertations/details/view.html'),
      controller: 'PublicDissertationDetailsController',
      controllerAs: 'vm'
    })
    .state('public.accessform', {
      url: '/form-access',
      template: require('./accessform/view.html'),
      controller: 'PublicAccessFormController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
