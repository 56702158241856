export default class NewsEditController {
  constructor(
    $scope,
    $state,
    NewsService,
    UIService,
    $translate,
    FileUploader
  ) {
    this.$state = $state;
    this.NewsService = NewsService;
    this.UI = UIService;
    this.$translate = $translate;

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );

    this.loadData();

    this.uploader = new FileUploader({
      url: `/api/assets/upload/news`,
      queueLimit: 1,
    });

    this.uploader.onSuccessItem = (item, response) => {
      // Save in DB
      this.data.image = {
        container: "news",
        year: moment().year().toString(),
        month: ("00" + (moment().month() + 1)).slice(-2),
        cover: response.result.name,
      };
    };
  }

  loadData = () => {
    this.NewsService.get(this.$state.params.id).then((r) => {
      this.data = r;
      this.data.date = new Date(r.date);
      this.data.expires = new Date(r.expires);
      this.loaded = true;
    });
  };

  save = () => {
    this.NewsService.save(this.data).then(() => {
      this.UI.addToast(this.$translate.instant("TEXT_NEWS_SAVED"));
      this.$state.go("app.news.own");
    });
  };

  upload = () => {
    this.UI.showDialog({
      size: "lg",
      template: require("../upload.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.uploader = this.uploader;

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close();
          };
        },
      ],
    }).then((r) => {});
  };
}

NewsEditController.$inject = [
  "$scope",
  "$state",
  "NewsService",
  "UIService",
  "$translate",
  "FileUploader",
];
