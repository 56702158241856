export default class NewsService {
  constructor(News, $q, AuthenticationService, AuthorizationService, Recycle) {
    this.Auth = AuthenticationService;
    this.Authorize = AuthorizationService;
    this.Recycle = Recycle;
    this.News = News;
    this.$q = $q;
  }

  //List the news who haven't expired
  listNews = (filter) => {
    let defer = this.$q.defer();
    this.News.find(filter)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  validate = (data) => {
    let messages = [];
    if (!data.title) messages.push("Insira um título");
    if (data.title && !data.title.pt) {
      messages.push("Insira um título em Português");
    }
    if (!data.summary) messages.push("Insira uma descrição");
    if (data.summary && !data.summary.pt) {
      messages.push("Insira uma descrição em Português");
    }
    if (!data.expires) messages.push("Insira uma data de expiração da notícia");
    return messages;
  };

  //Save News
  save = (data) => {
    let defer = this.$q.defer();
    this.News.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.News.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  delete = (news) => {
    let defer = this.$q.defer();
    let u = this.Auth.getUser();
    this.News.deleteById({
      id: news.id,
    })
      .$promise.then(() => {
        this.Recycle.create({
          model: "News",
          userId: u.email,
          object: JSON.stringify(news),
        })
          .$promise.then(() => {
            defer.resolve();
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

NewsService.$inject = [
  "News",
  "$q",
  "AuthenticationService",
  "AuthorizationService",
  "Recycle",
];
