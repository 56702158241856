export default class DashboardController {
  constructor(AuthenticationService, $window, UIService, $scope) {
    this.AuthenticationService = AuthenticationService;
    this.user = AuthenticationService.getUser();
    this.$window = $window;
    this.UI = UIService;
    this.features = require('./utils.json');

    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
  }

  goTo = data => {
    if (data.internal) {
      this.$window.location.href = data.url
    } else {
      this.UI.showConfirm('Vai sair da plataforma my.DEEC. Deseja continuar?').then(r => {
        if (r) {
          this.$window.location.href = data.url
        }
      });
    }

  };

  logout = () => {
    this.AuthenticationService.logout();
  };

  accountManagement = () => {
    this.UI.showConfirm(this.$translate.instant("TEXT_LEAVE")).then((r) => {
      if (r) {
        if (this.user.email.includes("student")) {
          // Go to inforestudante
          this.$window.location.href = "https://inforestudante.uc.pt/";
        } else {
          // Go to UCS
          this.$window.location.href =
            "https://myaccount.deec.uc.pt/univention/self-service/#profiledata";
        }
      }
    });
  };
}

DashboardController.$inject = ['AuthenticationService', '$window', 'UIService', '$scope'];
