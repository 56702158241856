import React, { useState } from "react";
import { DropzoneArea } from "mui-file-dropzone";
const FileUpload = ({ onFileSelect }) => {
    const [file, setFile] = useState(null);
    const handleFileUpload = (newFile) => {
        const selectedFile = newFile[0] || null;
        setFile(selectedFile);
        onFileSelect(selectedFile);
    };
    return (<div>
      <DropzoneArea onChange={handleFileUpload} acceptedFiles={["image/*", "application/pdf"]} dropzoneText="Drag and drop a file here or click" filesLimit={1}/>
    </div>);
};
export default FileUpload;
