export function routes($stateProvider) {
  $stateProvider
    .state("app.access", {
      url: "/access",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.access.form", {
      url: "/form-access",
      template: require("./form/view.html"),
      controller: "AccessFormController",
      controllerAs: "vm",
      memberOf: [
        "cn=deec,ou=groups,dc=ci,dc=uc,dc=pt",
        "cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt",
        "cn=Colaboradores,cn=groups,dc=deec,dc=uc,dc=pt",
        "cn=DEEC_My_Acessos,cn=groups,dc=deec,dc=uc,dc=pt",
        "cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt",
        "cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt",
      ],
    })
    .state("app.access.approval", {
      url: "/approval-access",
      template: require("./approval/view.html"),
      controller: "AccessApprovalController",
      controllerAs: "vm",
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt", "cn=DEEC_My_Acessos,cn=groups,dc=deec,dc=uc,dc=pt", "cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt", "cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt"],
    })
    .state("app.access.list", {
      url: "/list-access",
      template: require("./list/view.html"),
      controller: "AccessListController",
      controllerAs: "vm",
      memberOf: ["cn=DEEC_My_Acessos,cn=groups,dc=deec,dc=uc,dc=pt", "cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt", "cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt"],
    })
    .state("app.access.requests", {
      url: "/requests-access",
      template: require("./requests/view.html"),
      controller: "AccessRequestsController",
      controllerAs: "vm",
      memberOf: ["cn=DEEC_My_Acessos,cn=groups,dc=deec,dc=uc,dc=pt", "cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt", "cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt"],
    });
}
routes.$inject = ["$stateProvider"];
