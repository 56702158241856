export default class JobsOwnController {
  constructor($state, $scope, UIService, JobService) {
    this.JobService = JobService;
    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );
    this.loadData();
  }

  loadData = () => {
    this.JobService.my().then((r) => {
      this.total = r.total;
      this.data = r.data;
      this.loaded = true;
    });
  };

  isOwner = (data) => this.JobService.isOwner(data);
}

JobsOwnController.$inject = ["$state", "$scope", "UIService", "JobService"];
