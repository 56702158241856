export default class LoginController {
  constructor($rootScope, $scope, $state, $translate, $window, AuthenticationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$translate = $translate;
    this.$window = $window;
    this.Authentication = AuthenticationService;
    this.UI = UIService;
    this.email = '';
    this.password = '';
    this.error = '';
    this.waiting = false;
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
      $translate.use(val);
    }, true);

    $window.addEventListener("message", (message) => {
      $scope.$apply(() => {
        let data = message.data;
        if (data.type && data.type === 'oauth') {
          if (data.result === true) {
            this.UI.addToast("A redireccionar...");
            location.reload(true);
          } else {
            this.UI.addToast("Nenhuma conta associada!");
          }
        }

      });

    }, false);
  }

  createSSO = provider => {
    let opt = {};
    if (provider.hasOwnProperty("color")) {
      opt["background-color"] = provider.color;
    }
    if (provider.hasOwnProperty("text")) {
      opt["color"] = provider.text;
    }
    return opt;
  };

  performLogin = () => {
    this.waiting = true;
    this.Authentication.login(this.email, this.password).then((res) => {
      this.waiting = false;
      if (res.twofactor) {
        this.$state.go('auth.two-factor', {
          allow: true
        });
      } else {
        this.$state.go('app.dissertations.list');
      }
    }).catch((err) => {
      this.waiting = false;
      switch (err.data.error.code) {
        case 'LOGIN_FAILED':
          this.UI.addToast('Email ou password errados');
          break;
        case 'LOGIN_BLOCKED':
          this.UI.addToast('A conta está bloqueada');
          break;
      }
    });
  };

  changeLang = () => {
    if (this.lang === 'pt') {
      localStorage.setItem("lang", "en");
      this.$translate.use('en');
    } else {
      localStorage.setItem("lang", "pt");
      this.$translate.use('pt');
    }
  };

  handle = provider => {
    let width = 650;
    let height = 700;
    let left = (screen.width / 2) - (width / 2);
    let top = (screen.height / 2) - (height / 2);
    this.$window.open(`/auth/${provider.name}`, "", `toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=0,width=${width},height=${height},left=${left},top=${top}`);
  }
}

LoginController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$window', 'AuthenticationService', 'UIService'];
