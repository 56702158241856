import { routes } from "./routes";
import AdminAccountService from "./account/service";
import AdminAccountListController from "./account/list/controller";
import AdminAccountInsertController from "./account/insert/controller";

export default angular
  .module("app.admin", [])
  .config(routes)
  .service("AdminAccountService", AdminAccountService)
  .controller("AdminAccountListController", AdminAccountListController)
  .controller("AdminAccountInsertController", AdminAccountInsertController)
  .name;
