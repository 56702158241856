import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Dialog, Box, DialogContent, Stack, Typography, DialogTitle, useMediaQuery, useTheme, } from "@mui/material";
export default function ViewDialog({ title, body, image, open, onClose, }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (<Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth={true}>
      <DialogTitle sx={{ position: "absolute", top: 0 }}></DialogTitle>
      <DialogContent sx={{ p: 0 }} onClick={onClose}>
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: 1 }}>
          <Box sx={{
            width: "100%", // Ocupa 100% da largura do Card
            height: 'auto', // Ajusta a altura para manter a proporção
            background: image
                ? `url(${image}) center center no-repeat`
                : "url(/assets/images/default.jpg) center center no-repeat",
            //background: 'url(/assets/images/default.jpg) center center no-repeat', // para testar
            //background: 'url(/api/assets/download/projects/2024/05/280b6a20-1f09-42a2-9bde-473613115f43.png) center center no-repeat',
            backgroundSize: "contain",
            bgcolor: "black",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            aspectRatio: 1,
        }}></Box>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={2} sx={{
            width: 1,
            p: 2,
            color: "white",
            background: "rgba(0, 0, 0, 0.6)",
            position: "relative",
        }}>
            <Box sx={{
            flexShrink: 1,
            alignSelf: { xs: "flex-start", sm: "center" },
        }}>
              <Typography fontWeight="bold">{title}</Typography>
              <Typography variant="body2">{ReactHtmlParser(body)}</Typography>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>);
}
