export function routes($stateProvider) {
  $stateProvider
    .state('app.media', {
      url: '/media',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.media.material', {
      url: '/material',
      template: require("./material/view.html"),
      controller: 'ExplorerController',
      controllerAs: 'vm'
    })
    .state('app.media.own', {
      url: '/own',
      template: require('./own/view.html'),
      controller: 'MediaController',
      controllerAs: 'vm',
      //memberOf: ['cn=deec,ou=groups,dc=ci,dc=uc,dc=pt', 'cn=DEEC_Direccao,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Domain Admins,cn=groups,dc=deec,dc=uc,dc=pt']
    })
  ;
}

routes.$inject = ['$stateProvider'];
