export default class AccessService {
  constructor(Access, $q, AuthenticationService, AuthorizationService, Recycle) {
    this.Auth = AuthenticationService;
    this.Authorize = AuthorizationService;
    this.Recycle = Recycle;
    this.Access = Access;
    this.$q = $q;

    // Rooms available, to be used in forms
    this.entitySpaces = [
      {
        active: true,
        designacao: "BEST (T4.3)",
        selected: false,
        auto: false,
      },
      {
        active: false,
        designacao: "Clube Robótica (S4.2)",
        selected: false,
        auto: false,
      },
      { active: false, designacao: "NEEEC (S4.1)", selected: false },
    ];
    this.usableSpaces = [
      {
        active: false,
        designacao: "Anfiteatros",
        selected: false,
        auto: false,
      },
      {
        active: false,
        designacao: "Apoio à Limpeza (S3.3)",
        selected: false,
        auto: false,
      },
      {
        active: false,
        designacao: "Aprovisionamento (G2.7)",
        selected: false,
        auto: false,
      },
      {
        active: false,
        designacao: "Cacifos (G2.10)",
        selected: false,
        auto: false,
      },
      {
        active: false,
        designacao: "Entrada Piso 2",
        selected: false,
        auto: true,
      },
      {
        active: false,
        designacao: "Entrada Piso 6",
        selected: false,
        auto: true,
      },
      { active: false, designacao: "Informática (G2.6)", selected: false },
      {
        active: false,
        designacao: "Lab. Análise de Sistemas (T3.2)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Apoio Informático 1 (S4.3)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Apoio Informático 2 (S4.4)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Apoio Informático 3 (S6.3)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Apoio Informático 4 (T6.2)",
        selected: false,
      },
      { active: true, designacao: "Lab. Automação (R3.1)", selected: false },
      {
        active: true,
        designacao: "Lab. Comunicações Óticas (S3.2)",
        selected: false,
      },
      { active: false, designacao: "Lab. Controlo (S3.5)", selected: false },
      { active: false, designacao: "Lab. Electrónica (S5.3)", selected: false },
      {
        active: true,
        designacao: "Lab. Electrónica Potência (R5.1)",
        selected: false,
      },
      { active: true, designacao: "Lab. Energia (T6.3)", selected: false },
      {
        active: true,
        designacao: "Lab. Gestão Energia (T3.5)",
        selected: false,
      },
      {
        active: true,
        designacao: "Lab. Hiperfrequências (S6.1)",
        selected: false,
      },
      {
        active: true,
        designacao: "Lab. Máquinas Eléctricas (R4.1)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Medidas Instrumentação (S5.4)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Multi Disciplinar (S5.1)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Multi Disciplinar (S5.2)",
        selected: false,
      },
      {
        active: true,
        designacao: "Lab. Processamento Multimédia (S3.1)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Redes Sistemas Computadores (R6.1)",
        selected: false,
      },
      {
        active: true,
        designacao: "Lab. Simulação Teletráfego (T3.1)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Sistemas Digitais (R6.2)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Sistemas Electromecânicos (R5.2)",
        selected: false,
      },
      {
        active: false,
        designacao: "Lab. Telecomunicações (S6.3)",
        selected: false,
      },
      { active: false, designacao: "Manutenção (G2.4)", selected: false },
      { active: false, designacao: "Oficina (G0)", selected: false },
      { active: false, designacao: "Portão Garagem Exterior", selected: false },
      { active: false, designacao: "Portão Garagem Interior", selected: false },
      { active: false, designacao: "Sala de Avaliações", selected: false },
      { active: false, designacao: "Sala de Convívio (G2.1)", selected: false },
      { active: false, designacao: "Sala de Estudo (T4.2)", selected: false },
      {
        active: false,
        designacao: "Sala de Impressão (Gab. 3A4)",
        selected: false,
      },
      { active: false, designacao: "Sala de Reuniões (G2.2)", selected: false },
      { active: false, designacao: "Sala EFS (T5.3)", selected: false },
    ];
  }

  // Return a copy of the usable spaces for accesses
  getUsableSpaces = () => angular.copy(this.usableSpaces.filter((r) => r.active == true));

  getEntitySpaces = () => angular.copy(this.entitySpaces.filter((r) => r.active == true));

  setState = (id, state) => {
    let defer = this.$q.defer();
    this.Access.findById({ id }).$promise.then(r => {
      r.state = state;
      this.Access.upsert(r).$promise.then(_ => {
        defer.resolve(_);
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  //Save request
  save = (data) => {
    let defer = this.$q.defer();
    // Divide o pedido conforme os tipos de entidades (ou seja, um pedido para areas comuns, um para entidades e um para laboratórios)
    let promises = [];
    // Tem areas comuns?
    if (data.spaces.general.length > 0) {
      let o = angular.copy(data);
      o.responsibleEntityMail = "direcao@deec.uc.pt";
      o.spacesAccess = angular.copy(data.spaces.general);
      delete o.spaces;
      promises.push(this.Access.upsert(o).$promise);
    }
    // Tem entidades?
    if (data.spaces.entities.length > 0) {
      let o = angular.copy(data);
      o.responsibleEntityMail = "direcao@deec.uc.pt";
      o.spacesAccess = angular.copy(data.spaces.entities);
      delete o.spaces;
      promises.push(this.Access.upsert(o).$promise);
    }
    // Tem laboratórios?
    if (data.spaces.labs.length > 0) {
      let o = angular.copy(data);
      o.spacesAccess = angular.copy(data.spaces.labs);
      delete o.spaces;
      promises.push(this.Access.upsert(o).$promise);
    }
    // Aguarda por tudo e devolve o resultado
    this.$q
      .all(promises)
      .then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  //Load requests
  load = (filter) => {
    let defer = this.$q.defer();
    this.Access.find(filter)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  my = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let query = {
      filter: {
        where: {
          responsibleEntityMail: { like: user.email },
          state: 0
        }
      }
    }
    if (this.Authorize.belongsTo("cn=DEEC_My_Acessos,cn=groups,dc=deec,dc=uc,dc=pt")) {
      query = {
        filter: {
          where: {
            state: 0
          }
        }
      }
    }
    this.Access.find(query)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  delete = (request) => {
    let defer = this.$q.defer();
    let u = this.Auth.getUser();
    this.Access.deleteById({
      id: request.id,
    })
      .$promise.then(() => {
        this.Recycle.create({
          model: "Access",
          userId: u.email,
          object: JSON.stringify(request),
        })
          .$promise.then(() => {
            defer.resolve();
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Access.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  email = (mailOptions) => {
    let defer = this.$q.defer();
    this.Access.sendEmail({ details: mailOptions })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  // Process form spaces
  processSpaces1 = (data) => {
    let spaces = [];
    Object.keys(data.spacesAccess).forEach((k) => {
      if (data.spacesAccess[k] !== "") spaces.push(data.spacesAccess[k]);
    });
    return spaces;
  };

  // Process object spaces
  processSpaces2 = (data) => data.spacesAccess.filter((x) => x !== "");
}
AccessService.$inject = ["Access", "$q", "AuthenticationService", "AuthorizationService", "Recycle"];
