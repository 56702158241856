export default class PersonDetailsController {
  constructor($state, $scope, Person, Dissertation,UI) {
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
    this.id = $state.params.id;
    this.UI = UI;
    this.Person = Person;
    this.Dissertation = Dissertation;
    this.loadData();
  }

  loadData = () => {
    this.Person.teacher(this.id).then(r => {
      this.data = r;
      this.Dissertation.teacher(this.id).then(d => {
        this.dissertations = d;
        this.loaded = true;
      });
    });
  }
}

PersonDetailsController.$inject = ['$state', '$scope', 'PersonService', 'DissertationService', 'UIService'];
