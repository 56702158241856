export function routes($stateProvider) {
  $stateProvider
    .state('app.dissertations', {
      url: '/dissertations',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.dissertations.list', {
      url: '?{page:int}&{available:bool}&{semester:int}&{specialization:string}&{term:string}&{teacher:int}',
      template: require('./list/view.html'),
      controller: 'DissertationsListController',
      controllerAs: 'vm'
    })
    .state('app.dissertations.own', {
      url: '/own',
      template: require('./own/view.html'),
      controller: 'DissertationsOwnController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Dissertacoes,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.dissertations.assigned', {
      url: '/assigned',
      template: require('./assigned/view.html'),
      controller: 'DissertationsAssignedController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Dissertacoes,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.dissertations.insert', {
      url: '/insert',
      template: require('./insert/view.html'),
      controller: 'DissertationsInsertController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Dissertacoes,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.dissertations.edit', {
      url: '/:id/edit',
      template: require('./edit/view.html'),
      controller: 'DissertationsEditController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Dissertacoes,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.dissertations.details', {
      url: '/:id',
      template: require('./details/view.html'),
      controller: 'DissertationDetailsController',
      controllerAs: 'vm'
    })
  ;
}

routes.$inject = ['$stateProvider'];
