import angular from 'angular';
import uiRouter from "@uirouter/angularjs";

import {routes, routing} from './authentication.routes';
import LoginController from './login/login.controller';
import AuthenticationService from './authentication.service';
import RedirectController from "./redirect/redirect.controller";

export default angular.module('app.authentication', [uiRouter])
  .config(routes)
  .config(routing)
  .controller('LoginController', LoginController)
  .controller('RedirectController', RedirectController)
  .service('AuthenticationService', AuthenticationService)
  .name;
