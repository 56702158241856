import React from "react";
import { Alert, Snackbar } from "@mui/material";
const SnackbarComponent = (props) => {
    const handleClose = () => {
        props.onClose();
    };
    if (props.severity === "normal") {
        return (<Snackbar open={props.open} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} autoHideDuration={2500} onClose={handleClose} message={props.message}/>);
    }
    else {
        return (<Snackbar open={props.open} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} autoHideDuration={2500} onClose={handleClose} message={props.message}>
        <Alert severity={props.severity} sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>);
    }
};
export default SnackbarComponent;
