export function routes($stateProvider) {
  $stateProvider
    .state('app.jobs', {
      url: '/jobs',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.jobs.list', {
      url: '?{term:string}',
      template: require('./list/view.html'),
      controller: 'JobsListcontroller',
      controllerAs: 'vm',
      title: 'Contactos',
    })
    .state('app.jobs.own', {
      url: '/own',
      template: require('./own/view.html'),
      controller: 'JobsOwnController',
      controllerAs: 'vm',
      memberOf: ['cn=DEEC_My_Jobs,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Funcionarios,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.jobs.insert', {
      url: '/insert',
      template: require('./insert/view.html'),
      controller: 'JobInsertController',
      controllerAs: 'vm',
      memberOf: ['cn=DEEC_My_Jobs,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Funcionarios,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.jobs.edit', {
      url: '/:id/edit',
      template: require('./edit/view.html'),
      controller: 'JobsEditController',
      controllerAs: 'vm',
      memberOf: ['cn=DEEC_My_Jobs,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Funcionarios,cn=groups,dc=deec,dc=uc,dc=pt', 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.jobs.details', {
      url: '/:id',
      template: require('./details/view.html'),
      controller: 'JobsDetailscontroller',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
