import React from "react";
import { Box } from "@mui/material";
export default function Item({ image, onClick }) {
    return (<Box onClick={onClick} sx={{
            width: "100%", // Ocupa 100% da largura do Card
            height: 'auto', // Ajusta a altura para manter a proporção
            background: image
                ? `url(${image}) center center no-repeat`
                : "url(/assets/images/default.jpg) center center no-repeat",
            //background: 'url(/assets/images/default.jpg) center center no-repeat', // para testar
            //background: 'url(/api/assets/download/projects/2024/05/280b6a20-1f09-42a2-9bde-473613115f43.png) center center no-repeat', // para testar
            backgroundSize: "contain",
            bgcolor: "black",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            aspectRatio: 1,
        }}></Box>);
}
