export default class DissertationsOwnController {
  constructor($state, $scope, UIService, DissertationService) {
    this.DissertationService = DissertationService;
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
    this.loadData();
  }

  loadData = () => {
    this.DissertationService.my().then(r => {
      this.total = r.total;
      this.data = r.data;
      this.loaded = true;
    });
  }
}

DissertationsOwnController.$inject = ['$state', '$scope', 'UIService', 'DissertationService'];
