export default class AdminAccountListController {
  constructor($state, $scope, UIService, AdminAccountService) {
    this.$state = $state;
    this.UI = UIService;
    this.Account = AdminAccountService;

    this.Account.getDomains().then((n) => {
      this.domains = n;
    });
    this.filter = {
      term: $state.params.term || "",
      page: $state.params.page || 1,
      domain: $state.params.domain || "",
      created: $state.params.created !== null ? $state.params.created : true,
    };

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );
    this.loadData();
  }

  clear = () => {
    this.filter = {
      page: 1,
      term: "",
      created: false,
    };
  };

  createFilter = () => {
    this.lang = localStorage.getItem("lang");
    let where = {};
    let or = [];
    let ob = {};
    let prop = "";
    let pattern = "";
    if (this.filter.created === true) {
      where.state = 1;
    } else {
      where.state = 0;
    }
    if (this.filter.term !== "") {
      // Filter by name
      prop = `displayName`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      // Cargo
      prop = "occupation";
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      // Recovery Email
      prop = "recovery";
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      where.or = or;
    }
    if (this.filter.domain) {
      where.domain = this.filter.domain;
    }
    return {
      where: where,
      order: "id DESC",
      limit: 15,
      skip: (this.filter.page - 1) * 15,
    };
  };

  showForValidation = (row) => {
    this.UI.showDialog({
      size: 'lg',
      template: require("./dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.user = row;

          $scope.ok = () => {
            $scope.$close(true);
          };

          $scope.ok = () => {
            $scope.$close(false);
          };

          $scope.cancel = () => {
            $scope.$dismiss("cancel");
          };
        },
      ],
    }).then((r) => {
      this.Account.validate(row.id)
        .then((r) => {
          this.loadData();
        })
        .catch((e) => {});
    });
  };

  loadData = () => {
    this.loaded = false;
    this.$state.go(
      "app.admin.account.list",
      {
        page: this.filter.page,
        term: this.filter.term,
        created: ~~this.filter.created,
        domain: this.filter.domain,
      },
      {
        notify: false,
        reload: false,
        location: "replace",
        inherit: true,
      }
    );
    this.Account.list(this.createFilter()).then((r) => {
      this.total = r.total;
      if (r.data.length > 0) {
        this.start = 1 + (this.filter.page - 1) * 15;
        this.end =
          this.start + r.data.length > r.data.length
            ? r.data.length
            : this.start + r.data.length;
      } else {
        this.start = 0;
        this.end = 0;
      }
      this.data = r.data;
      this.loaded = true;
    });
  };

  previous = () => {
    this.filter.page--;
    if (this.filter.page === 0) this.filter.page = 1;
    this.loadData();
  };

  next = () => {
    this.filter.page++;
    if (this.filter.page > Math.round(this.total / 15))
      this.filter.page = Math.round(this.total / 15);
    this.loadData();
  };
}

AdminAccountListController.$inject = [
  "$state",
  "$scope",
  "UIService",
  "AdminAccountService",
];
