export default class DissertationsListController {
  constructor($state, $scope, UIService, DissertationService) {
    this.$state = $state;
    this.UI = UIService;
    this.DissertationService = DissertationService;

    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);

    DissertationService.specializations().then(r => {
      this.specializations = r;
      DissertationService.teachers((err, t) => {
        this.teachers = t;
        DissertationService.semesters((err, n) => {
          this.semesters = n;
          this.filter = {
            term: $state.params.term || "",
            page: $state.params.page || 1,
            semester: this.semesters.find(r => r.id === ($state.params.semester || 0)),
            available: $state.params.available !== null ? $state.params.available : true,
            specialization: $state.params.specialization ? this.specializations.find(r => r.id == $state.params.specialization) : undefined,
            teacher: $state.params.teacher ? this.teachers.find(r => r.id == $state.params.teacher) : undefined,
          };
          this.loadData();
        });
      });
    });
  }

  clear = () => {
    this.filter = {
      page: 1,
      term: "",
      available: false,
      semester: this.semesters.find(s => s.id === this.$state.params.semester || 0),
      specialization: undefined,
      teacher: undefined,
    }
  };

  createFilter = () => {
    this.lang = localStorage.getItem("lang");
    let where = {
    };
    let or = [];
    let ob = {};
    let prop = "";
    let pattern = "";
    if (this.filter.available === true) {
      where.state = 1;
      where.student = {
        eq: null
      };
    } else {
      where.state = 1;
    }
    if (this.filter.teacher !== undefined) {
      prop = "teacher.id";
      ob = {};
      ob[prop] = this.filter.teacher.id;
      or.push(ob);
      where.or = or;
    }
    if (this.filter.term !== "") {
      // Filter by title
      prop = `title.${this.lang}`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i"
      };
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      // Teacher name
      prop = "teacher.username";
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      // Teacher email
      prop = "teacher.email";
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      where.or = or;
    }
    where.semester = this.filter.semester.id || 0;
    return {
      available: this.filter.available,
      specialization: this.filter.specialization !== undefined ? this.filter.specialization.id : undefined,
      teacher: this.filter.teacher !== undefined ? this.filter.teacher.id : undefined,
      term: this.filter.term,
      where: where,
      order: 'created DESC',
      limit: 15,
      skip: (this.filter.page - 1) * 15
    }
  };

  loadData = () => {
    this.loaded = false;
    this.$state.go('app.dissertations.list', {
      page: this.filter.page,
      term: this.filter.term,
      available: ~~this.filter.available,
      semester: this.filter.semester.id || 0,
      specialization: this.filter.specialization || undefined,
      teacher: this.filter.teacher || undefined
    }, {
      notify: false,
      reload: false,
      location: 'replace',
      inherit: true
    });
    this.DissertationService.list(this.createFilter()).then(r => {
      this.total = r.total;
      if (r.data.length > 0) {
        this.start = 1 + (this.filter.page - 1) * 15;
        this.end = (this.start + r.data.length) > r.data.length ? r.data.length : this.start + r.data.length;
      } else {
        this.start = 0;
        this.end = 0;
      }
      // Masonry effect
      r.data.forEach(row => {
        let dissertations = angular.copy(row.dissertations);
        let arrays = [[], [], []];
        let currIdx = 0;
        dissertations.forEach(d => {
          arrays[currIdx++].push(d);
          if (currIdx == 3) {
            currIdx = 0;
          }
        });
        row.dissertations = arrays;
      });
      this.data = r.data;
      this.loaded = true;
    });
  };

  previous = () => {
    this.filter.page--;
    if (this.filter.page === 0)
      this.filter.page = 1;
    this.loadData();
  };

  next = () => {
    this.filter.page++;
    if (this.filter.page > Math.round(this.total / 15))
      this.filter.page = Math.round(this.total / 15);
    this.loadData();
  }
}

DissertationsListController.$inject = ['$state', '$scope', 'UIService', 'DissertationService'];
