export default class EvaluationsInsertController {
  constructor($scope, $state, EvaluationsService, UIService, FileUploader) {
    // New uploader instance
    this.uploader = new FileUploader({
      url: '/api/assets/upload/evaluations',
      queueLimit: 1
    });
    // Limit to PDF
    this.uploader.filters.push({
      name: 'pdfOnly',
      fn: (item ,options) => {
        let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|'.indexOf(type) !== -1;
      }
    });
    // Notify user that file was inserted
    this.uploader.onAfterAddingFile = () => {
      this.UI.addToast("Ficheiro adicionado!");
    };
    // Wait for file to be completed
    this.uploader.onSuccessItem = (file, response, status, headers) => {
      if (response.result) {
        this.data.sheet = {
          container: response.result.container,
          year: response.result.year,
          month: response.result.month,
          name: response.result.name,
          size: response.result.size,
        };
      }
    };

    // If we have attachments, wait them for being uploaded then upsert
    this.uploader.onCompleteAll = () => {
      this.waiting.close();
      this.submit();
    };

    this.EvaluationsService = EvaluationsService;
    this.UI = UIService;
    EvaluationsService.courses().then(r => {
      this.courses = r;
      this.loaded = true;
    });
  }
}

EvaluationsInsertController.$inject = ['$scope', '$state', 'EvaluationsService', 'UIService', 'FileUploader'];

