export default class NewsListController {
  constructor($state, $scope, NewsService, UIService, AuthenticationService) {
    this.$state = $state;
    this.NewsService = NewsService;
    this.Auth = AuthenticationService;
    this.UI = UIService;

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );

    this.loadData();
  }

  //Load News
  loadData = () => {
    this.loaded = false;
    this.newsLists = [];
    let filter = {
      filter: { where: { expires: { gte: new Date() } }, order: "date DESC" },
    };
    this.NewsService.listNews(filter).then((data) => {
      // Masonry effect
      let arrays = [[], [], []];
      let currIdx = 0;
      data.forEach((row) => {
        arrays[currIdx++].push(row);
        if (currIdx == 3) {
          currIdx = 0;
        }
      });
      this.loaded = true;
      this.newsLists = arrays;
      this.loaded = true;
    });
  };

  //Opens new tab
  newTab = (source) => {
    if (source) {
      window.open(source, "_blank");
    }
  };

  downloadFile = (d) => {
    return `/api/assets/download/news/${d.year}/${d.month}/${d.cover}`;
  };
}

NewsListController.$inject = [
  "$state",
  "$scope",
  "NewsService",
  "UIService",
  "AuthenticationService",
];
