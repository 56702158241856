import {routes} from './routes';
import NewsService from "./service";
import NewsListController from "./list/controller";
import NewsOwnController from './own/controller';
import NewsInsertController from "./create/controller";
import NewsDetailscontroller from './details/controller';
import NewsEditController from './edit/controller';

export default angular.module('app.news', [])
  .config(routes)
  .service('NewsService', NewsService)
  .controller('NewsListController', NewsListController)
  .controller('NewsOwnController', NewsOwnController)
  .controller('NewsInsertController', NewsInsertController)
  .controller('NewsDetailscontroller', NewsDetailscontroller)
  .controller('NewsEditController', NewsEditController)
  .name;
