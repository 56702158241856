import {routes} from './routes';
import PersonService from "./service";
import PersonListController from "./list/controller";
import PersonDetailsController from './details/controller';

export default angular.module('app.persons', [])
  .config(routes)
  .service('PersonService', PersonService)
  .controller('PersonDetailsController', PersonDetailsController)
  .controller('PersonListController', PersonListController)
  .filter('name', () => {
    return (name) => {
      let names = name.split(" ");
      if (names.length > 1) {
        return `${names.shift()} ${names.pop()}`
      } else {
        return name;
      }
    }
  })
  .name;
