import angular from "angular";
import MultiClamp from "multi-clamp";
import uiRouter from "@uirouter/angularjs";
import pmdTabs from "./directives/tabs/pmdTabs";
import pmdPickers from "./directives/pickers/pmdPickers";
import pmdAlert from "./directives/toasts/pmdAlert";
import pmdAccordion from "./directives/accordion/pmdAccordion";
import pmdProgress from "./directives/progress/pmdProgress";
import pmdDialog from "./directives/dialogs/pmdDialog";
import pmdTooltip from "./directives/others/pmdTooltip";
import pmdAvatar from "./directives/others/pmdAvatar";
import pmdInfinite from "./directives/others/pmdInfinite";
import pmdSelect from "./directives/others/pmdSelect";

import { routes } from "./interface.routes";
import LayoutController from "./layout/layout.controller";
import ProfileController from "./profile/profile.controller";
import ChangelogController from "./changelog/changelog.controller";
import ChangelogEditorController from "./changelog/change/change.controller";
import UIService from "./UI.service";
import DashboardController from "./dashboard/dashboard.controller";
import ChangelogService from "./changelog/changelog.service";
import { react2angular } from "react2angular";
import GridView from "./dashboard/view.tsx";

export default angular
  .module("app.interface", [uiRouter, pmdTabs, pmdPickers, pmdAlert, pmdAccordion, pmdProgress, pmdDialog, pmdTooltip, pmdAvatar, pmdInfinite, pmdSelect])
  .config(routes)
  .component("gridView", react2angular(GridView, [], ["$translate"]))
  .controller("LayoutController", LayoutController)
  .controller("ProfileController", ProfileController)
  .controller("ChangelogController", ChangelogController)
  .controller("ChangelogEditorController", ChangelogEditorController)
  .controller("DashboardController", DashboardController)
  .service("UIService", UIService)
  .service("ChangelogService", ChangelogService)
  .filter("skip", () => {
    return (input, start) => {
      // Limit exists, but skip doesn't...
      start = +start; // Parse to int :)
      return input.splice(start);
    };
  })
  .directive("maxlines", function () {
    return {
      restrict: "A",
      require: "ngModel",
      link: function (scope, elem, attrs, ngModel) {
        let maxLines = 1;
        attrs.$observe("maxlines", function (val) {
          maxLines = parseInt(val);
        });
        ngModel.$validators.maxlines = function (modelValue, viewValue) {
          let numLines = (modelValue || "").split("\n").length;
          return numLines <= maxLines;
        };
        attrs.$observe("maxlinesPreventEnter", function (preventEnter) {
          // if attribute value starts with 'f', treat as false. Everything else is true
          preventEnter = (preventEnter || "").toLocaleLowerCase().indexOf("f") !== 0;
          if (preventEnter) {
            addKeypress();
          } else {
            removeKeypress();
          }
        });

        function addKeypress() {
          elem.on("keypress", function (event) {
            // test if adding a newline would cause the validator to fail
            if (event.keyCode == 13 && !ngModel.$validators.maxlines(ngModel.$modelValue + "\n", ngModel.$viewValue + "\n")) {
              event.preventDefault();
            }
          });
        }

        function removeKeypress() {
          elem.off(".maxlines");
        }

        scope.$on("$destroy", removeKeypress);
      },
    };
  })
  .filter("nl2br", () => {
    return (value) => {
      return String(value).replace(/\n/g, "<br />");
    };
  })
  .filter("moment", function () {
    return function (input, momentFn /*, param1, param2, ...param n */) {
      var args = Array.prototype.slice.call(arguments, 2),
        momentObj = moment(input);
      return momentObj[momentFn].apply(momentObj, args);
    };
  })
  .filter("dateDiff", () => {
    return (value, preholder) => {
      // Calculate diference from given date to now
      return moment.utc(value).fromNow(preholder);
    };
  })
  .directive("clamp", () => {
    return {
      restrict: "A",
      link: (scope, el, att) => {
        new MultiClamp(el, {
          ellipsis: "...",
          clamp: att.clamp || "auto",
        });
      },
    };
  }).name;
