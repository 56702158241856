export default class AccessListController {
    constructor($scope, $state, $translate, AccessService, UIService) {
        this.$state = $state;
        this.$translate = $translate;
        this.AccessService = AccessService;
        this.UI = UIService;

        $scope.$watch(() => {
            return localStorage.getItem("lang")
        }, (val) => {
            this.lang = val;
        }, true);

        this.loadData();
    }

    loadData = () => {
        this.loaded = false;
        let filter = { filter: { order: 'dateStart DESC' } };
        this.AccessService.load(filter).then(list => {
            this.data = list;
            this.loaded = true;
        });
    }

    delete = (id) => {
        this.AccessService.get(id).then(r => {this.request = r;});
        this.UI.showConfirm(this.$translate.instant("TEXT_CONFIRM_DELETE")).then(r => {
            this.AccessService.delete(this.request).then(() => {
                this.loadData();
                this.UI.addToast(this.$translate.instant("TEXT_DELETED"));
            }).catch(e => {
                this.loadData();
                this.UI.addToast(this.$translate.instant("TEXT_FAILED_DELETED"));
            });
        });
    }
}
AccessListController.$inject = ['$scope', '$state', '$translate', 'AccessService', 'UIService'];