import React, { useState, useRef, useCallback, useEffect } from "react";
import { Editor, EditorState, RichUtils, ContentState } from "draft-js";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { mdiCodeBlockTags, mdiFileCode, mdiFormatBold, mdiFormatHeader1, mdiFormatHeader2, mdiFormatHeader3, mdiFormatHeader4, mdiFormatHeader5, mdiFormatHeader6, mdiFormatItalic, mdiFormatListBulleted, mdiFormatListNumbered, mdiFormatUnderline, } from "@mdi/js";
import { ToggleButton, Stack, Typography, } from "@mui/material";
import Icon from "@mdi/react";
const styleMap = {
    CODE: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};
function getBlockStyle(block) {
    switch (block.getType()) {
        case "blockquote":
            return "RichEditor-blockquote";
        default:
            return null;
    }
}
const BlockStyleControls = ({ editorState, onToggle }) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (<div className="RichEditor-controls">
      <ToggleButtonGroup value={null} size="small" onChange={onToggle} aria-label="text formatting">
        <ToggleButton value="header-one" aria-label="H1" selected={currentStyle.has("header-one")} onClick={(e) => {
            e.preventDefault();
            onToggle("header-one");
        }}>
          <Icon path={mdiFormatHeader1} size={1}/>
        </ToggleButton>
        <ToggleButton value="header-two" aria-label="H2" selected={currentStyle.has("header-two")} onClick={(e) => {
            e.preventDefault();
            onToggle("header-two");
        }}>
          <Icon path={mdiFormatHeader2} size={1}/>
        </ToggleButton>

        <ToggleButton value="header-three" aria-label="H3" selected={currentStyle.has("header-three")} onClick={(e) => {
            e.preventDefault();
            onToggle("header-three");
        }}>
          <Icon path={mdiFormatHeader3} size={1}/>
        </ToggleButton>
        <ToggleButton value="header-four" aria-label="H4" selected={currentStyle.has("header-four")} onClick={(e) => {
            e.preventDefault();
            onToggle("header-four");
        }}>
          <Icon path={mdiFormatHeader4} size={1}/>
        </ToggleButton>
        <ToggleButton value="header-five" aria-label="H5" selected={currentStyle.has("header-five")} onClick={(e) => {
            e.preventDefault();
            onToggle("header-five");
        }}>
          <Icon path={mdiFormatHeader5} size={1}/>
        </ToggleButton>
        <ToggleButton value="header-six" aria-label="H6" selected={currentStyle.has("header-six")} onClick={(e) => {
            e.preventDefault();
            onToggle("header-six");
        }}>
          <Icon path={mdiFormatHeader6} size={1}/>
        </ToggleButton>

        <ToggleButton value="blockquote" aria-label="Blockquote" selected={currentStyle.has("blockquote")} onClick={(e) => {
            e.preventDefault();
            onToggle("blockquote");
        }}>
          Blockquote
        </ToggleButton>
        <ToggleButton value="unordered-list-item" aria-label="UL" selected={currentStyle.has("unordered-list-item")} onClick={(e) => {
            e.preventDefault();
            onToggle("unordered-list-item");
        }}>
          <Icon path={mdiFormatListBulleted} size={1}/>
        </ToggleButton>
        <ToggleButton value="ordered-list-item" aria-label="OL" selected={currentStyle.has("ordered-list-item")} onClick={(e) => {
            e.preventDefault();
            onToggle("ordered-list-item");
        }}>
          <Icon path={mdiFormatListNumbered} size={1}/>
        </ToggleButton>
        <ToggleButton value="code-block" aria-label="Code Block" selected={currentStyle.has("code-block")} onClick={(e) => {
            e.preventDefault();
            onToggle("code-block");
        }}>
          <Icon path={mdiCodeBlockTags} size={1}/>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>);
};
const InlineStyleControls = ({ editorState, onToggle }) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (<div className="RichEditor-controls">
      <ToggleButtonGroup value={null} size="small" onChange={onToggle} aria-label="text formatting">
        <ToggleButton value="BOLD" aria-label="Bold" selected={currentStyle.has("BOLD")} onMouseDown={(e) => {
            e.preventDefault();
            onToggle("BOLD");
        }}>
          <Icon path={mdiFormatBold} size={1}/>
        </ToggleButton>
        <ToggleButton value="ITALIC" aria-label="Italic" selected={currentStyle.has("ITALIC")} onMouseDown={(e) => {
            e.preventDefault();
            onToggle("ITALIC");
        }}>
          <Icon path={mdiFormatItalic} size={1}/>
        </ToggleButton>
        <ToggleButton value="UNDERLINE" aria-label="Underline" selected={currentStyle.has("UNDERLINE")} onMouseDown={(e) => {
            e.preventDefault();
            onToggle("UNDERLINE");
        }}>
          <Icon path={mdiFormatUnderline} size={1}/>
        </ToggleButton>
        <ToggleButton value="CODE" aria-label="Monospace" selected={currentStyle.has("CODE")} onMouseDown={(e) => {
            e.preventDefault();
            onToggle("CODE");
        }}>
          <Icon path={mdiFileCode} size={1}/>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>);
};
const TextEditor = ({ translate, onTextChange, text }) => {
    const [editorState, setEditorState] = useState(() => {
        if (text !== "") {
            const contentState = ContentState.createFromText(text);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    });
    const editor = useRef(null);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    useEffect(() => {
        const text = editorState.getCurrentContent().getPlainText();
        if (onTextChange) {
            onTextChange(text);
        }
        const handleStorageChange = (event) => {
            setLang(localStorage.getItem("lang"));
        };
        const intervalId = setInterval(handleStorageChange, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, [editorState, onTextChange]);
    const focusEditor = useCallback(() => {
        editor.current.focus();
    }, []);
    const handleKeyCommand = useCallback((command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return true;
        }
        return false;
    }, [editorState]);
    const toggleBlockType = useCallback((blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }, [editorState]);
    const toggleInlineStyle = useCallback((inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }, [editorState]);
    let className = "RichEditor-editor";
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText() &&
        contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
    }
    return (<div>
      <Typography variant="subtitle1" color="#A9A9A9">
        {`${translate.instant("INPUT_DESCRIPTION")}`}
      </Typography>
      <div className="RichEditor-root">
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
            <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle}/>

            <BlockStyleControls editorState={editorState} onToggle={toggleBlockType}/>
          </Stack>
          <div className={className} onClick={focusEditor}>
            <Editor blockStyleFn={getBlockStyle} customStyleMap={styleMap} editorState={editorState} handleKeyCommand={handleKeyCommand} onChange={setEditorState} placeholder={`${translate.instant("TEXT_EDITOR_TEX")}`} ref={editor} textAlignment="right" spellCheck={true} value={text}/>
          </div>
        </Stack>
      </div>
    </div>);
};
export default TextEditor;
