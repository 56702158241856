export default class JobsDetailscontroller {
  constructor($scope, $state, $translate, JobService, UIService) {
    this.$state = $state;
    this.$translate = $translate;
    this.JobService = JobService;
    this.UI = UIService;

    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);

    if (!$state.params) {
      $state.go('app.jobs.list');
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    this.loaded = false;
    this.JobService.get(this.$state.params.id).then(r => {
      this.data = r;
      this.loaded = true;
    }).catch(e => {
      this.$state.go('app.jobs.list');
    });
  }

  isOwner = () => {
    if (!this.loaded) {
      return false;
    }
    return this.JobService.isOwner(this.data);
  };

  hide = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state === 1) {
        this.JobService.hide(this.data).then(r => {
          this.UI.addToast(this.$translate.instant("TEXT_JOB_HIDDEN"));
          this.loadData();
        });
      }
    }
  }

  show = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state === 0) {
        this.JobService.show(this.data).then(r => {
          this.UI.addToast(this.$translate.instant("TEXT_JOB_VISIBLE"));
          this.loadData();
        });
      }
    }
  }

  delete = () => {
    if (!this.isOwner()) {
      this.UI.addToast(this.$translate.instant("TEXT_NO_PERMISSIONS"));
    } else {
      if (this.data.state <= 0) {
        this.UI.showConfirm(this.$translate.instant("TEXT_CONFIRM_DELETE")).then(r => {
          this.JobService.delete(this.data).then(() => {
            this.$state.go('app.jobs.own');
            this.UI.addToast(this.$translate.instant("TEXT_DELETED"));
          }).catch(e => {
            this.$state.go('app.jobs.own');
            this.UI.addToast(this.$translate.instant("TEXT_FAILED_DELETED"));
          });
        });
      }
    }
  }
}

JobsDetailscontroller.$inject = ['$scope', '$state', '$translate', 'JobService', 'UIService'];
