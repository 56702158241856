export default class AuthorizationService {
  constructor($rootScope, $state, $q, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.AuthenticationService = AuthenticationService;
    this.$q = $q;
    this.roles = [];
    this.groups = [];

    $rootScope.$on('$stateChangeStart', () => {
      if (AuthenticationService.isAuthenticated() && AuthenticationService.getUser().hasOwnProperty('email'))
        this.loadACLs();
    });

    if (AuthenticationService.isAuthenticated() && AuthenticationService.getUser().hasOwnProperty('email'))
      this.loadACLs();
  }

  getAllowed = () => {
    return this.roles;
  };


  isWhitelisted = (module, email) => {
    if (module == undefined || email == undefined) {
      return false;
    }
    let whitelist = require('./whitelist.json');
    let list = whitelist.find(r => module.includes(r.module));
    if (list) {
      return list.emails.includes(email);
    }
    return false;
  };

  // Obtem lista de todas as funcionalidades presentes nas roles associadas ao user...
  loadACLs = () => {
    const user = this.AuthenticationService.getUser();
    if (_.isEmpty(user))
      return;
    let k = [];
    user.memberOf = user.memberOf || [];
    user.memberOf.forEach(g => {
      k.push(g);
    });
    // Important, otherwise we will have an exception in canPerform...
    k.push('$authenticated');
    this.roles = _.uniqBy(k);
    this.groups = _.uniqWith(k, _.isEqual);
  };

  isAuthenticated = () => {
    return this.AuthenticationService.isAuthenticated() && this.roles.length > 0;
  };

  // Verifica se o utilizador pode executar conjunto de roles...
  canPerform = (roles) => {
    return this.roles.some(r => roles.includes(r));
  };

  // Verifica se o utilizador pertence a um grupo
  belongsTo = (group) => {
    return this.groups.some(r => group.includes(r));
  };
}

AuthorizationService.$inject = ['$rootScope', '$state', '$q', 'AuthenticationService'];
