String.prototype.includes = function (str) {
  var returnValue = false;

  if (this.indexOf(str) !== -1) {
    returnValue = true;
  }

  return returnValue;
};

Array.prototype.isEmpty = function () {
  return !!this.length
};

export function ApplicationInterceptor($rootScope, $state, $http, $timeout, AuthenticationService, AuthorizationService, UIService) {
  $rootScope.$on('$stateChangeStart', (event, to) => {
    // Get Server Status - this is async, so no worries :)
    $http.get('/api/status').then((res) => {
      $rootScope.status = res.data;
      $rootScope.$broadcast('$receivedServerStatus', res.data);
    });

    // User is authenticated? In case of an exception, set authenticated as off
    let loggedIn = AuthenticationService.isAuthenticated() || false;
    // 2FA control, view isAllowed() function for more info
    let allowed = AuthenticationService.isAllowed() || false;

    if (!loggedIn) {
      // If user not authenticated and trying to access routes that are not related to authentication, deny access
      if (!loggedIn && !to.name.includes('auth')) {
        if (!to.name.includes('public')) {
          AuthenticationService.clearUser();
          $state.go('auth.login');
          event.preventDefault(); // Denying access
        }
      }
    } else {
      // If user is authenticated and tries to access routes that are authentication
      if (loggedIn && to.name.includes('auth') && allowed) {
        $state.go('app.dashboard');
        event.preventDefault(); // Denying access
      } else {
        if (!allowed && !to.name.includes('auth.two-factor')) {
          $state.go('auth.two-factor');
        } else {
          let user = AuthenticationService.getUser();
          if (user.blocked) {
            UIService.addToast('A sua conta foi bloqueada');
            AuthenticationService.logout();
          }

          // Check if user has access to role of state (if exists)
          if (to.hasOwnProperty('memberOf')) {
            if (!AuthorizationService.canPerform(to.memberOf) && !AuthorizationService.isWhitelisted(to.name, user.email)) {
              $state.go('app.dashboard');
              event.preventDefault(); // Denying access
            }
          }
        }
      }
    }
  });

  $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
    if (toState.hasOwnProperty('title')) {
      $rootScope.title = toState.title;
    }
  });
}

ApplicationInterceptor.$inject = ['$rootScope', '$state', '$http', '$timeout', 'AuthenticationService', 'AuthorizationService', 'UIService'];
