export default class JobsListcontroller {
  constructor($scope, $state, JobService, UIService) {
    this.$state = $state;
    this.JobService = JobService;
    this.UI = UIService;

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );

    this.JobService.type().then((r) => {
      this.types = r;
      this.filter = {
        term: $state.params.term || "",
        type: this.types.find((s) => s.id === ($state.params.type || 0)),
      };
      this.loadData();
    });
  }

  loadData = () => {
    this.loaded = false;
    let filter = {
      term: this.filter.term,
    };
    if (this.filter.type && this.filter.type.id) {
      filter.type = this.filter.type.id;
    }

    this.$state.go("app.jobs.list", filter, {
      notify: false,
      reload: false,
      location: "replace",
      inherit: true,
    });
    this.JobService.list(this.createFilter()).then((data) => {
      // Masonry effect
      let arrays = [[], [], []];
      let currIdx = 0;
      data.forEach((row) => {
        arrays[currIdx++].push(row);
        if (currIdx == 3) {
          currIdx = 0;
        }
      });
      this.loaded = true;
      this.data = arrays;
    });
  };

  createFilter = () => {
    let where = {
      state: 1,
    };
    where.expires = {
      gte: new Date(),
    };
    if (this.filter.term !== "") {
      // Filter by title
      let prop = `title.${this.lang}`;
      let pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      where[prop] = {
        like: pattern,
      };
    }
    return {
      where: where,
      order: "expires DESC",
    };
  };

  clear = () => {
    this.filter = {
      page: 1,
      term: "",
      type: 0,
    };
  };
}

JobsListcontroller.$inject = ["$scope", "$state", "JobService", "UIService"];
