export function routes($stateProvider) {
  $stateProvider
    .state("app.mobility", {
      url: "/mobility",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.mobility.view", {
      url: "/",
      template: require("./view/view.html"),
      controller: "MobilityController",
      controllerAs: "vm"
    });
}

routes.$inject = ["$stateProvider"];
