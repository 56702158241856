export default class EvaluationsService {
  constructor($q, Course) {
    this.$q = $q;
    this.Course = Course;
  }

  courses = () => {
    let defer = this.$q.defer();
    this.Course.find().$promise.then(r => defer.resolve(r)).catch(e => {
      defer.reject(e);
    });
    return defer.promise;
  };

  save = (evaluation) => {
    let defer = this.$q.defer();
    return defer.promise;
  }
}

EvaluationsService.$inject = ['$q', 'Course'];
