export default class AuthenticationService {
  constructor($rootScope, Users, LoopBackAuth, $q, $state, $cookies) {
    this.$rootScope = $rootScope;
    this.Users = Users;
    this.LoopBackAuth = LoopBackAuth;
    this.$q = $q;
    this.$cookies = $cookies;
    this.user = {};
    this.allowed = {
      access: false,
    };
    this.$state = $state;
    if (this.isAuthenticated()) {
      $cookies.put("access_token", LoopBackAuth.accessTokenId);
      $cookies.put("userId", LoopBackAuth.currentUserId);
      if (!localStorage.getItem("user")) {
        this.allowed.access = true;
        this.user.id = LoopBackAuth.currentUserId;
        localStorage.setItem("allowed", JSON.stringify(this.allowed));
        this.getUserInfo(true);
      } else {
        this.allowed = JSON.parse(localStorage.getItem("allowed"));
        this.user = JSON.parse(localStorage.getItem("user"));
        //this.getUserInfo();
      }
    }
  }

  // Attempt login with given email and password
  login = (email, password) => {
    let deferred = this.$q.defer();
    this.Users.login({ rememberMe: true }, { email, password })
      .$promise.then((res) => {
        this.user = res.user;
        this.allowed.access = true;

        localStorage.setItem("user", JSON.stringify(this.user));
        localStorage.setItem("allowed", JSON.stringify(this.allowed));

        if (this.allowed.access) {
          location.reload(true); // Solution proposed by @antonio.gomes, this fixes the bug that requires F5 to reload jQuery
        } else {
          deferred.resolve(this.user);
        }
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  };

  // Logouts user and invalidates access token in the server
  logout = () => {
    localStorage.removeItem("user");
    this.Users.logout()
      .$promise.then(() => {
        this.clearUser();
        location.reload(true); // Solution proposed by @antonio.gomes, this fixes the bug that requires F5 to reload jQuery
      })
      .catch((err) => {
        // In case of error ignore, since it will be catched by the Authentication Handler
        this.clearUser();
        location.reload(true); // Solution proposed by @antonio.gomes, this fixes the bug that requires F5 to reload jQuery
      });
  };

  // Clears cache that saves user data
  clearUser = () => {
    this.LoopBackAuth.clearUser();
    this.LoopBackAuth.clearStorage();
    this.LoopBackAuth.currentUserId = null;
    this.LoopBackAuth.accessTokenId = null;
    this.LoopBackAuth.save();
    delete this.$cookies["access_token"];
    this.allowed.access = false;
    localStorage.removeItem("user");
    localStorage.removeItem("allowed");
  };

  // Get authenticated user access token
  getToken = () => {
    return this.LoopBackAuth.accessTokenId;
  };

  // Get authenticated user Id
  getId = () => {
    return this.user.id;
  };

  // Retrieve user information in cache
  getUser = () => {
    return this.user;
  };

  // Retrieve user information from the server
  getUserInfo = (reload) => {
    this.Users.findById({
      id: this.user.id,
      filter: {
        include: [
          "identities",
          {
            relation: "groups",
            scope: {
              include: {
                relation: "rolegroup",
                scope: {
                  where: {
                    active: true,
                  },
                  include: {
                    relation: "role",
                  },
                },
              },
            },
          },
        ],
      },
    })
      .$promise.then((user) => {
        this.user = user;
        localStorage.setItem("user", JSON.stringify(this.user));
        if (reload) {
          location.reload(true);
        }
      })
      .catch((err) => {
        this.logout();
      });
  };

  // Ask server to validate the access token in cache
  isAuthenticated = () => {
    return this.Users.isAuthenticated();
  };

  // If access is enabled (Enabled automatically if account has 2FA turned off)
  isAllowed = () => {
    return this.allowed.access;
  };

  isWhitelisted = () => false;
}

AuthenticationService.$inject = ["$rootScope", "User", "LoopBackAuth", "$q", "$state", "$cookies"];
