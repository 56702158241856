export default class ProjectService {
  constructor(
    Project,
    AuthenticationService,
    AuthorizationService,
    PersonService,
    $q,
    FileUploader
  ) {
    this.Project = Project;
    this.Auth = AuthenticationService;
    this.Authorize = AuthorizationService;
    this.Person = PersonService;
    this.$q = $q;
    this.FileUploader = FileUploader;
  }

  generateUUID = () => {
    let uuid = "",
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  };

  createProject = (data) => {
    let defer = this.$q.defer();

    this.Project.create(data)
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  getProject = (id) => {
    let defer = this.$q.defer();
    this.Project.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    }).$promise.then((project) => {
      defer.resolve(project);
    });
    return defer.promise;
  };

  getProjects = () => {
    let defer = this.$q.defer();
    this.Project.find({}).$promise.then((project) => {
      defer.resolve(project);
    });
    return defer.promise;
  };

  getOwnProjects = () => {
    let user = this.Auth.getUser();
    let defer = this.$q.defer();
    this.Project.find({
      filter: {
        where: {
          "createdBy.id": user.id,
        },
      },
    }).$promise.then((project) => {
      defer.resolve(project);
    });
    return defer.promise;
  };

  updateProject = (data) => {
    let defer = this.$q.defer();

    this.Project.upsert(data)
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });

    return defer.promise;
  };

  deleteProject = (project) => {
    let defer = this.$q.defer();

    this.Project.deleteById({ id: project.id })
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });

    return defer.promise;
  };

  getLoggedUser = () => {
    let defer = this.$q.defer();
    try {
      let user = this.Auth.getUser();
      if (user) {
        defer.resolve(user);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      defer.reject(error);
    }
    return defer.promise;
  };

  getAllUsers = () => {
    let defer = this.$q.defer();
    try {
      let users = this.Person.all();
      if (users) {
        defer.resolve(users);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      defer.reject(error);
    }
    return defer.promise;
  };

  getUserId = () => {
    let defer = this.$q.defer();
    try {
      let id = this.Auth.getId();
      if (id) {
        defer.resolve(id);
      } else {
        throw new Error("ID not found");
      }
    } catch (error) {
      defer.reject(error);
    }
    return defer.promise;
  };
}
ProjectService.$inject = [
  "Project",
  "AuthenticationService",
  "AuthorizationService",
  "PersonService",
  "$q",
  "FileUploader",
];
