export function routes($stateProvider) {
  $stateProvider
    .state('app.projects', {
      url: '/projects',
      abstract: true,
      template: '<ui-view></ui-view>',
    })
    .state('app.projects.list', {
      url: '/list',
      template: require('./list/view.html'),
      controller: 'ProjectsListController',
      controllerAs: 'vm',
    })
    .state('app.projects.own', {
      url: '/own',
      template: require('./own/view.html'),
      controller: 'ProjectsOwnController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Projetos,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.projects.insert', {
      url: '/insert',
      template: require('./insert/view.html'),
      controller: 'ProjectsInsertController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Projetos,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.projects.details', {
      url: '/{id:string}', 
      template: require('./details/view.html'),
      controller: 'ProjectsDetailsController',
      controllerAs: 'vm',
    })
    .state('app.projects.update', {
      url: '/update/{id:string}', 
      template: require('./update/view.html'),
      controller: 'ProjectsUpdateController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=DEEC_My_Projetos,cn=groups,dc=deec,dc=uc,dc=pt", 'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
  ;
}

routes.$inject = ['$stateProvider'];
