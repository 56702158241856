export default class ChargeService {
  constructor(
    Charge,
    AuthenticationService,
    AuthorizationService,
    PersonService,
    $q
  ) {
    this.Charge = Charge;
    this.Auth = AuthenticationService;
    this.Authorize = AuthorizationService;
    this.Person = PersonService;
    this.$q = $q;
  }
  
  createCharge = (data) => {
    let defer = this.$q.defer();

    this.Charge.create(data)
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  getCharge = (id) => {
    let defer = this.$q.defer();
    this.Charge.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    }).$promise.then((charge) => {
      defer.resolve(charge);
    });
    return defer.promise;
  };

  getCharges = () => {
    let defer = this.$q.defer();
    this.Charge.find({}).$promise.then((charge) => {
      defer.resolve(charge);
    });
    return defer.promise;
  };

  updateCharge = (data) => {
    let defer = this.$q.defer();

    this.Charge.upsert(data)
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });

    return defer.promise;
  };

  deleteChargeById = (id) => {
    let defer = this.$q.defer();
    this.Charge.deleteById({ id: id })
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  getLoggedUser = () => {
    let defer = this.$q.defer();
    try {
      let user = this.Auth.getUser();
      if (user) {
        defer.resolve(user);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      defer.reject(error);
    }
    return defer.promise;
  };

  getAllUsers = () => {
    let defer = this.$q.defer();
    try {
      let users = this.Person.all();
      if (users) {
        defer.resolve(users);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      defer.reject(error);
    }
    return defer.promise;
  };

  getUserId = () => {
    let defer = this.$q.defer();
    try {
      let id = this.Auth.getId();
      if (id) {
        defer.resolve(id);
      } else {
        throw new Error("ID not found");
      }
    } catch (error) {
      defer.reject(error);
    }
    return defer.promise;
  };
  
}
ChargeService.$inject = [
  "Charge",
  "AuthenticationService",
  "AuthorizationService",
  "PersonService",
  "$q",
];
