export default class DissertationsAssignedController {
  constructor($state, $scope, UIService, DissertationService) {
    this.$state = $state;
    this.UI = UIService;
    this.DissertationService = DissertationService;
    $scope.$watch(() => {
      return localStorage.getItem("lang")
    }, (val) => {
      this.lang = val;
    }, true);
    this.loadData();
  }

  createFilter = () => {
    return {
      where: {
        state: 1,
        student: {
          neq: null
        }
      },
      order: 'id DESC',
    }
  };

  loadData = () => {
    this.loaded = false;
    /*
    this.$state.go('app.dissertations.list', {
      page: this.filter.page,
      term: this.filter.term,
      available: ~~this.filter.available,
      semester: this.filter.semester.id || 0,
      specialization: this.filter.specialization || undefined
    }, {
      notify: false,
      reload: false,
      location: 'replace',
      inherit: true
    });
    */
    this.DissertationService.specializations().then(r => {
      this.specializations = r;
      this.DissertationService.list(this.createFilter()).then(r => {
        let list = [];
        r.data.forEach(r => {
          list.push(...r.dissertations.filter(a => a.student != null && a.state == 1));
        });
        this.data = list;
        this.total = list.length;
        this.loaded = true;
      });
    });
  };

  previous = () => {
    this.filter.page--;
    if (this.filter.page === 0)
      this.filter.page = 1;
    this.loadData();
  };

  next = () => {
    this.filter.page++;
    if (this.filter.page > Math.round(this.total / 15))
      this.filter.page = Math.round(this.total / 15);
    this.loadData();
  }
}

DissertationsAssignedController.$inject = ['$state', '$scope', 'UIService', 'DissertationService'];
