export default class ExplorerController {
  constructor(MediaService, UIService) {
    this.Media = MediaService;
    this.UI = UIService;
    this.data = [];
    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    this.Media.explorer()
      .then((r) => {
        this.data = r;
        this.loaded = true;
      })
      .catch(() => {
        this.UI.addToast("Erro ao carregar directório");
      });
  };
}

ExplorerController.$inject = ["MediaService", "UIService"];
