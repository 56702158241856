window.$ = window.jQuery;
/* Angular Dependencies */
import angular from "angular";
import uiRouter from '@uirouter/angularjs';
import config from "./app.config";

/* Client Modules */
import lbServices from "./modules/loopback/lbServices";
import Interface from "./modules/interface";
import Authentication from "./modules/authentication";
import Authorization from "./modules/authorization";

import Dissertations from "./modules/dissertations";
import Persons from "./modules/persons";
import Evaluations from "./modules/evaluations";
import Jobs from "./modules/jobs";
import Erasmus from "./modules/mobility";
import Public from "./modules/public";
import Admin from "./modules/administration";
import Media from "./modules/multimedia";
import News from "./modules/news";
import Accesses from "./modules/accesses";
import Projects from "./modules/projects";
import Charges from "./modules/charges";


import { ApplicationInterceptor } from "./app.interceptor";
require("angular-translate");
import angularLocalePT from "angular-i18n/pt-pt";
import ngResource from "angular-resource";
import ngSanitize from "angular-sanitize";
import ngCookies from "angular-cookies";
import ngAnimate from "angular-animate";
import uiSelect from "ui-select";
import textAngular from "textangular";
import "angular-file-upload";
import "@uirouter/angularjs/release/stateEvents";
require("@mdi/font/css/materialdesignicons.min.css");
require("ui-select/dist/select.min.css");
import "flag-icon-css/css/flag-icon.css";

moment.locale("pt");

let app = angular.module("app", [
  uiRouter,
  "ui.router.state.events",
  ngResource,
  ngSanitize,
  ngAnimate,
  ngCookies,
  uiSelect,
  textAngular,
  angularLocalePT,
  "angularFileUpload",
  "pascalprecht.translate",
  lbServices,
  Interface,
  Authentication,
  Authorization,
  // Modules
  Accesses,
  Dissertations,
  Persons,
  Evaluations,
  Jobs,
  Erasmus,
  Public,
  Admin,
  Media,
  News,
  Projects,
  Charges
]);

// Loopback related settings + HTML5 mode
app.config(config);
// Enable translations
app.factory("translateStorage", () => {
  return {
    put: (name, value) => {
      localStorage.setItem("lang", value);
    },
    get: (name) => {
      localStorage.getItem("lang");
    },
  };
});
app.config([
  "$translateProvider",
  ($translateProvider) => {
    let lang = localStorage.getItem("lang") || "pt";
    let pt = require("./modules/interface/i18n/intl_pt.arb");
    let en = require("./modules/interface/i18n/intl_en.arb");
    $translateProvider
      .translations("pt", JSON.parse(pt))
      .translations("en", JSON.parse(en))
      .useStorage("translateStorage")
      .preferredLanguage(lang);

    $translateProvider.useSanitizeValueStrategy(null);
  },
]);
// Disable this is if you want to disable login
app.run(ApplicationInterceptor);
// Others
app.run([
  "$rootScope",
  ($rootScope) => {
    $rootScope.uiBase = {
      name: "TETAS",
      description: "Tiago Engineered Template in AngularJS for Streamline",
      version: "1.0.2",
    };
  },
]);

// UI-Select config
app.config([
  "uiSelectConfig",
  function (uiSelectConfig) {
    uiSelectConfig.theme = "bootstrap";
    uiSelectConfig.skipFocusser = true;
  },
]);

// TextAngular config
app.config([
  "$provide",
  function ($provide) {
    $provide.decorator("taOptions", [
      "$delegate",
      function (taOptions) {
        // $delegate is the taOptions we are decorating
        // here we override the default toolbars and classes specified in taOptions.
        taOptions.forceTextAngularSanitize = false; // set false to allow the textAngular-sanitize provider to be replaced
        taOptions.keyMappings = []; // allow customizable keyMappings for specialized key boards or languages
        taOptions.toolbar = [
          ["bold", "italics", "underline"],
          ["ul", "outdent", "indent"],
          ["redo", "undo", "clear"],
        ];
        taOptions.classes = {
          focussed: "",
          toolbar: "btn-toolbar",
          toolbarGroup: "btn-group",
          toolbarButton:
            "btn btn-xs btn-default pmd-btn-flat pmd-ripple-effect",
          toolbarButtonActive: "active",
          disabled: "disabled",
          textEditor: "form-control",
          htmlEditor: "form-control",
        };
        return taOptions; // whatever you return will be the taOptions
      },
    ]);
    // Replace css icons
    $provide.decorator("taTools", [
      "$delegate",
      function (taTools) {
        taTools.bold.iconclass = " mdi mdi-format-bold";
        taTools.italics.iconclass = "mdi mdi-format-italic";
        taTools.underline.iconclass = " mdi mdi-format-underline";
        taTools.ul.iconclass = " mdi mdi-format-own-bulleted";
        taTools.indent.iconclass = " mdi mdi-format-indent-increase";
        taTools.outdent.iconclass = " mdi mdi-format-indent-decrease";
        taTools.undo.iconclass = " mdi mdi-undo";
        taTools.redo.iconclass = " mdi mdi-redo";
        taTools.clear.iconclass = " mdi mdi-format-clear";
        taTools.html.iconclass = " mdi mdi-code-tags";
        return taTools;
      },
    ]);
  },
]);
