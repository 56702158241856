function routing($locationProvider, LoopBackResourceProvider, $animateProvider) {
  let url = window.location.host;
  if (url.indexOf(':3000') >= 0) {
    url.indexOf('http') >= 0 ? url = url : url= 'http://' + url;
    LoopBackResourceProvider.setUrlBase(url + '/api');
  } else {
    LoopBackResourceProvider.setUrlBase('/api');
  }

  // Remove AngularJS hashbang
  $locationProvider.html5Mode(true);
  // Enable class to disable ng-animate for a specific element
  $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
}

routing.$inject = ['$locationProvider', 'LoopBackResourceProvider', '$animateProvider'];

export default routing;
