export function routes($stateProvider) {
  $stateProvider
    .state("app.admin", {
      url: "/admin",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.admin.account", {
      url: "/account",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.admin.account.list", {
      url:
        "?{page:int}&{created:bool}&{domain:string}&{term:string}",
      template: require("./account/list/view.html"),
      controller: "AdminAccountListController",
      controllerAs: "vm",
      memberOf: "cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt",
    })
    .state("app.admin.account.insert", {
      url: "/insert",
      template: require("./account/insert/view.html"),
      controller: "AdminAccountInsertController",
      controllerAs: "vm",
      memberOf: "cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt",
    });
}

routes.$inject = ["$stateProvider"];
