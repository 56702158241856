import { isUndefined } from "angular";

export default class DissertationService {
  constructor(Dissertation, Specialization, AuthenticationService, Person, Recycle, Bio, $q) {
    this.Dissertation = Dissertation;
    this.Bio = Bio;
    this.Specialization = Specialization;
    this.Auth = AuthenticationService;
    this.Recycle = Recycle;
    this.Person = Person;
    this.$q = $q;

    this.s = [{
      id: 0,
      title: {
        pt: 'Ambos os semestres',
        en: 'Both semesters'
      },
    },
    {
      id: 1,
      title: {
        pt: '1º semestre',
        en: '1st semester'
      },
    },
    {
      id: 2,
      title: {
        pt: '2º semestre',
        en: '2nd semester'
      },
    }
    ];
    this.spec = [];
    this.t = [];
  }

  teachers = (callback) => {
    if (this.t.length == 0) {
      this.Person.teachers().then(row => {
        row.forEach(r => {
          let name = "";
          let names = r.username.split(" ");
          if (names.length > 1) {
            name = `${names[0]} ${names[names.length - 1]}`;
          } else {
            name = r.username;
          }
          r.prefered = name;
        })
        this.t = row;
        callback(null, this.t);
      });
    } else {
      callback(null, this.t);
    }
  }

  semesters = (callback) => {
    callback(null, this.s);
  };

  close = (dissertation) => {
    // Close dissertation, doesn't allow any more changes to happen and requires to be forked
    let defer = this.$q.defer();
    // State = 0 means its closed or finished, depending if it has a student associated or not
    dissertation.state = 0;
    dissertation.$save().then(r => {
      defer.resolve(r);
    }).catch(e => {
      defer.reject(e);
    });
    return defer.promise;
  }

  hide = (dissertation) => {
    let defer = this.$q.defer();
    // State = 2 means its hidden from the list, even if it has a student associated
    dissertation.state = 2;
    dissertation.$save().then(r => {
      defer.resolve(r);
    }).catch(e => {
      defer.reject(e);
    });
    return defer.promise;
  }

  show = (dissertation) => {
    let defer = this.$q.defer();
    // State = 1 means its visible from the list
    dissertation.state = 1;
    dissertation.$save().then(r => {
      defer.resolve(r);
    }).catch(e => {
      defer.reject(e);
    });
    return defer.promise;
  }

  specializations = () => {
    let defer = this.$q.defer();
    if (this.spec.length) {
      defer.resolve(this.spec);
    } else {
      this.Specialization.find().$promise.then(r => {
        this.spec = r;
        defer.resolve(r);
      }).catch(e => {
        defer.reject(e);
      })
    }
    return defer.promise;
  };

  fork = (dissertation) => {
    let defer = this.$q.defer();
    let d = angular.copy(dissertation);
    // Remove fields to be marked as "avaiable"
    // ID is generated, so remove it
    delete d.id;
    // Delete stuff
    delete d.creationDate;
    // avaiable => state = 1 and student = null
    d.state = 1;
    d.student = null;
    // Remove aplications
    d.proposal = [];
    // Remove internal and external supervisors
    d.internal = [];
    d.external = [];
    this.Dissertation.create(d).$promise.then(r => {
      defer.resolve(r);
    }).catch(e => {
      defer.reject(e);
    });
    return defer.promise;
  };


  list = (filter) => {
    let defer = this.$q.defer();
    this.Person.teachers().then(r => {
      let promises = [];
      if (filter.teacher) {
        r = r.filter(r => r.id == filter.teacher);
      }
      for (let i = 0; i < r.length; i++) {
        let teacher = r[i];
        let query = { filter: { where: {} } };
        query.filter.where['teacher.id'] = teacher.id;
        // School year and semester filter
        let schoolYear = "";
        let nextSchoolYear = "";
        // Get current semester
        let semester = 0;
        let date = moment();
        if (date.month() >= 9 && date.month() <= 12) {
          semester = 1;
          schoolYear = `${date.year()}/${date.year() + 1}`
          nextSchoolYear = `${date.year() + 1}/${date.year() + 2}`
        } else {
          semester = 2;
          schoolYear = `${date.year() - 1}/${date.year()}`
          nextSchoolYear = `${date.year()}/${date.year() + 1}`
        }
        if (filter.available) {
          query.filter.where.state = 1;
          query.filter.where.student = { eq: null }
        } else {
          query.filter.where.state = 1;
        }
        if (filter.where.semester == 1) {
          query.filter.where.or = query.filter.where.or || [];
          /*
          // Procura dissertações do 1º semestre
          if(semester == 1){ // Se o semestre atual for o 1º, mostras o 1º semestre deste ano
            query.filter.where.or.push({ and: [{ semester: 1 }, { year: schoolYear }] });
          }else if(semester == 2){ // Se o semestre atual for o 2º, mostra o 1º semestre do proximo ano
            query.filter.where.or.push({ and: [{ semester: 1 }, { year: nextSchoolYear }] });
          }
          */
          // Procura dissertações do 1º semestre do ano lectivo atual e do proximo
          query.filter.where.or.push({ and: [{ semester: 1 }, { year: schoolYear }] });
          query.filter.where.or.push({ and: [{ semester: 1 }, { year: nextSchoolYear }] });
        } else if (filter.where.semester == 2) {
          query.filter.where.or = query.filter.where.or || [];
          // Procura dissertações do 2º semestre do ano lectivo atual e do proximo
          query.filter.where.or.push({ and: [{ semester: 2 }, { year: schoolYear }] });
          query.filter.where.or.push({ and: [{ semester: 2 }, { year: nextSchoolYear }] });
        } else {
          query.filter.where.or = query.filter.where.or || [];
          /*
          // Procura dissertações do ano letivo atual e do proximo
          if(semester == 1){
            query.filter.where.or.push({ and: [{ semester: 1 }, { year: schoolYear }] });
            query.filter.where.or.push({ and: [{ semester: 2 }, { year: schoolYear }] });
          }else if(semester == 2){
            query.filter.where.or.push({ and: [{ semester: 2 }, { year: schoolYear }] });
            query.filter.where.or.push({ and: [{ semester: 1 }, { year: nextSchoolYear }] });
          }
          */
          // Procura dissertações dos 2 semestres do ano lectivo atual e do proximo
          query.filter.where.or.push({ and: [{ semester: 1 }, { year: schoolYear }] });
          query.filter.where.or.push({ and: [{ semester: 1 }, { year: nextSchoolYear }] });
          query.filter.where.or.push({ and: [{ semester: 2 }, { year: schoolYear }] });
          query.filter.where.or.push({ and: [{ semester: 2 }, { year: nextSchoolYear }] });
          /*query.filter.where.or.push({ and: [{ semester: 0 }, { year: schoolYear }] });
          query.filter.where.or.push({ and: [{ semester: 0 }, { year: nextSchoolYear }] });*/
        }
        if (filter.term) {
          query.filter.where.or = [];
          let q = {};
          let ob = `title.pt`;
          q[ob] = { like: `.*${filter.term}.*`, options: 'i' }
          query.filter.where.or.push(q);
          q = {};
          ob = `title.en`;
          q[ob] = { like: `.*${filter.term}.*`, options: 'i' }
          query.filter.where.or.push(q);
          /*q = {};
          ob = `resume.pt`;
          q[ob] = { like: `.*${filter.term}.*`, options: 'i' }
          query.filter.where.or.push(q);
          q = {};
          ob = `resume.en`;
          q[ob] = { like: `.*${filter.term}.*`, options: 'i' }
          query.filter.where.or.push(q);*/
        }
        if (filter.specialization) {
          query.filter.where['specialization.id'] = filter.specialization;
        }
        let d = this.$q.defer();
        this.Dissertation.find(query).$promise.then(diss => {
          teacher.dissertations = diss;
          d.resolve(teacher);
        }).catch(e => d.reject());
        promises.push(d.promise);
      }
      this.$q.all(promises).then(_ => {
        let toReturn = [];
        if (filter.teacher) {
          toReturn = _;
        } else {
          toReturn = _.filter(a => a.dissertations.length > 0);
        }
        let o = {
          total: toReturn.length,
          data: toReturn
        };
        defer.resolve(o);
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  /*
  list = (filter) => {
    let defer = this.$q.defer();
    this.Dissertation.count({
      where: filter.where
    }).$promise.then(r => {
      this.Dissertation.find({
        filter: filter
      }).$promise.then(list => {
        defer.resolve({
          total: r.count,
          data: list
        });
      }).catch(err => {
        defer.reject(err);
      });
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  };*/

  validate = (dissertation) => {
    let ok = true;
    let messages = [];
    // Validate title
    if (!dissertation.title) {
      ok = false;
      messages.push("Insira um título");
    } else {
      if (!dissertation.title.pt || !dissertation.title.en) {
        if (!dissertation.title.pt) {
          messages.push("Insira o título em Português")
        }
        if (!dissertation.title.en) {
          messages.push("Insira o título em Inglês")
        }
        ok = false;
      }
    }
    // Description
    if (!dissertation.description) {
      ok = false;
      messages.push("Insira uma descrição");
    } else {
      if (!dissertation.description.pt || !dissertation.description.en) {
        if (!dissertation.description.pt) {
          messages.push("Insira a descrição em Português")
        }
        if (!dissertation.description.en) {
          messages.push("Insira a descrição em Inglês")
        }
        ok = false;
      }
    }
    if (!dissertation.specialization) {
      messages.push("Escolha uma especialização");
      ok = false;
    }
    if (!dissertation.year) {
      messages.push("Escolha um ano letivo");
      ok = false;
    }
    if (!dissertation.semester) {
      messages.push("Escolha um semestre");
      ok = false;
    }
    return {
      result: ok,
      messages: messages
    }
  };

  isOwner = (dissertation) => {
    let user = this.Auth.getUser();
    if (!user) {
      return false;
    }
    return dissertation.teacher.id === user.id;
  };

  save = (dissertation) => {
    dissertation.teacher = dissertation.teacher || this.Auth.getUser();
    dissertation.state = dissertation.state || 1;
    dissertation.semester = dissertation.semester || 0;
    dissertation.proposal = dissertation.proposal || [];
    dissertation.internal = dissertation.internal || [];
    dissertation.external = dissertation.external || [];

    if(dissertation.student === undefined){
      dissertation.student = null;
    } 
    let defer = this.$q.defer();
    let validation = this.validate(dissertation);
    if (validation.result) {
      this.Dissertation.upsert(dissertation).$promise.then(data => {
        defer.resolve(data);
      }).catch(e => {
        defer.reject(['Não foi possível guardar a dissertação']);
      });
    } else {
      defer.reject(validation.messages);
    }
    return defer.promise;
  };

  get = id => {
    let defer = this.$q.defer();
    this.Dissertation.findOne({
      filter: {
        where: {
          id: id
        },
      }
    }).$promise.then(r => {
      if (r) {
        this.Bio.find({
          filter: {
            where: {
              userId: r.teacher.id
            }
          }
        }).$promise.then(b => {
          if (b.length > 0) {
            r.teacher.biography = b[0].biography;
          }
          defer.resolve(r);
        }).catch(e => {
          defer.resolve(r);
        });
      } else {
        defer.resolve(r);
      }

    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  my = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Dissertation.find({
      filter: {
        where: {
          'teacher.id': user.id,
        },
        order: 'id DESC'
      }
    }).$promise.then(r => {
      defer.resolve({
        total: r.length,
        data: r
      });
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  application = (id) => {
    let defer = this.$q.defer();
    this.get(id).then(r => {
      r.proposal = r.proposal || [];
      let u = this.Auth.getUser();
      u.date = new Date();
      delete u.memberOf;
      r.proposal.push(u);
      r.$save().then(() => {
        defer.resolve(true)
      }).catch(() => defer.reject(false));
    }).catch(() => defer.reject(false));
    return defer.promise;
  };

  teacher = (id) => {
    let defer = this.$q.defer();
    let query = { filter: { where: {} } };
    query.filter.where['teacher.id'] = id;
    // School year and semester filter
    let schoolYear = "";
    // Get current semester
    let semester = 0;
    let date = moment();
    if (date.month() >= 9 && date.month() <= 12) {
      semester = 1;
      schoolYear = `${date.year()}/${date.year() + 1}`
    } else {
      semester = 2;
      schoolYear = `${date.year() - 1}/${date.year()}`
    }
    //query.filter.where.semester = semester;
    query.filter.where.year = schoolYear;
    this.Dissertation.find(query).$promise.then(r => {
      defer.resolve(r);
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  canApply = (dissertation) => {
    return dissertation.proposal.find(i => i.id === this.Auth.getUser().id) === undefined && this.Auth.getUser() && this.Auth.getUser().email.includes("student");
  };

  delete = (dissertation) => {
    let defer = this.$q.defer();
    let u = this.Auth.getUser();
    this.Dissertation.deleteById({
      id: dissertation.id
    }).$promise.then(() => {
      this.Recycle.create({
        model: 'Dissertation',
        userId: u.email,
        object: JSON.stringify(dissertation)
      }).$promise.then(() => { defer.resolve() }).catch(() => defer.reject(false));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }
}

DissertationService
  .$inject = ['Dissertation', 'Specialization', 'AuthenticationService', 'PersonService', 'Recycle', 'Bio', '$q'];
