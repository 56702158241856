import {routes} from './routes';
import AccessService from "./service";
import AccessFormController from "./form/controller";
import AccessApprovalController from "./approval/controller";
import AccessListController from "./list/controller";
import AccessRequestsController from "./requests/controller";

export default angular.module('app.access', [])
  .config(routes)
  .service('AccessService', AccessService)
  .controller('AccessFormController', AccessFormController)
  .controller('AccessApprovalController', AccessApprovalController)
  .controller('AccessListController', AccessListController)
  .controller('AccessRequestsController', AccessRequestsController)
  .name;
