export default class ChargesInsertController {
  constructor($state, $scope, UIService, ChargeService) {
    this.$state = $state;
    this.UI = UIService;
    this.ChargeService = ChargeService;

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );
  }
}

ChargesInsertController.$inject = [
  "$state",
  "$scope",
  "UIService",
  "ChargeService",
];
