export function routes($stateProvider) {
  $stateProvider
    .state('app.contacts', {
      url: '/contacts',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.contacts.list', {
      url: '?{page:int}&{type:int}&{term:string}',
      template: require('./list/view.html'),
      controller: 'PersonListController',
      controllerAs: 'vm',
      title: 'Contactos',
    })
    .state('app.contacts.view', {
      url: '/{id:int}',
      template: require('./details/view.html'),
      controller: 'PersonDetailsController',
      controllerAs: 'vm',
      title: 'Informação do docente',
    })
  ;
}

routes.$inject = ['$stateProvider'];
