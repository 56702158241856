export default class PersonListController {
  constructor($state, $scope, $translate, PersonService, UIService, AuthenticationService) {
    this.$state = $state;
    this.$scope = $scope;
    this.$translate = $translate;
    this.PersonService = PersonService;
    this.Auth = AuthenticationService;
    this.UI = UIService;

    this.types = this.PersonService.types();
    this.shownCards = 20;

    this.filter = {
      term: $state.params.term || "",
      page: $state.params.page || 1,
      type: this.types.find(s => s.id === ($state.params.type || 0)),
    };

    $scope.$watch(() => {
      return localStorage.getItem("lang");
    }, (val) => {
      this.lang = val;
    }, true);
    this.PersonService.all().then(r => {
      this.teachers = r.teachers;
      this.staff = r.staff;
      this.isr = r.isr;
      this.it = r.it;
      this.inesc = r.inesc;
      this.list = r.list
      this.loadData();
    });
  }

  clear = () => {
    this.filter = {
      page: 1,
      term: "",
      type: 0
    };
  };

  loadData = () => {
    this.loaded = false;
    //TODO: Filter
    this.filterList = [];
    if (this.filter.type) {
      if (this.filter.type.id === 0) {
        this.filterList.push(...this.list);
        //Filter Repeated Names
        this.filterList = this.filterList.filter((value,index,arr)=>arr.findIndex(value2=>(value2.email===value.email))===index);
      } else if (this.filter.type.id === 1) {
        this.filterList.push(...this.teachers);
      } else if (this.filter.type.id == 2) {
        this.filterList.push(...this.staff);
      } else if (this.filter.type.id == 3) {
        this.filterList.push(...this.isr);
      } else if (this.filter.type.id == 4) {
        this.filterList.push(...this.it);
      } else if (this.filter.type.id == 5) {
        this.filterList.push(...this.inesc);
      }
    } else {
      this.filterList = angular.copy(this.list);
    }
    if (this.filter.term.length > 0) {
      this.filterList = this.filterList.filter(d => d.username.toLowerCase().includes(this.filter.term.toLowerCase()) || d.email.toLowerCase().includes(this.filter.term.toLowerCase()));
    }
    this.total = this.filterList.length;
    if (this.$scope.$$destroyed) {
      return;
    }
    this.$state.go('app.contacts.list', {
      page: this.filter.page,
      term: this.filter.term,
      type: this.filter.type ? this.filter.type.id : 0
    }, {
      notify: false,
      reload: false,
      location: 'replace',
      inherit: true
    });
    if (this.total > 0) {
      this.start = (this.filter.page - 1) * this.shownCards + 1;
      this.end = this.start + this.shownCards;
    } else {
      this.start = 0;
      this.end = 0;
    }
    this.filterList = this.filterList.slice(this.start - 1, this.end - 1);
    if (this.filter.term.length > 0) {
      this.total = this.filterList.length;
    }
    this.end = this.start - 1 + this.filterList.length;
    if (this.end < 0) {
      this.end = 0;
    }

    // Add output data for relevant positions (for output)
    this.filterList.forEach(f => {
      f.relevantPositions = [];
      // Docentes
      if (f.memberOf && f.memberOf.length > 0) {
        if (f.memberOf.includes("cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt"))
          f.relevantPositions.push("TEXT_TEACHER_DEEC");
        if (f.memberOf.includes("cn=Nao Docentes,cn=groups,dc=deec,dc=uc,dc=pt"))
          f.relevantPositions.push("TEXT_STAFF_DEEC");
        if (f.memberOf.includes("cn=ISR,cn=groups,dc=deec,dc=uc,dc=pt"))
          f.relevantPositions.push("TEXT_ISR");
        if (f.memberOf.includes("cn=IT,cn=groups,dc=deec,dc=uc,dc=pt"))
          f.relevantPositions.push("TEXT_IT");
        if (f.memberOf.includes("cn=INESC,cn=groups,dc=deec,dc=uc,dc=pt"))
          f.relevantPositions.push("TEXT_INESC");
      }
    });

    this.loaded = true;
  };

  previous = () => {
    this.filter.page--;
    if (this.filter.page === 0)
      this.filter.page = 1;
    this.loadData();
  };

  next = () => {
    this.filter.page++;
    if (this.filter.page > Math.round(this.total / this.shownCards))
      this.filter.page = Math.round(this.total / this.shownCards);
    this.loadData();
  };

  getContactType = (contact) => {
    let a = contact.split(" ").join("");
    let r = new RegExp("[239][0-9]{6,}");
    if (r.test(a)) {
      return "DDI";
    } else {
      return "TEXT_INTERNAL_CONTACT";
    }
  };

  isNotStudent = () => {
    let user = this.Auth.getUser();
    return !user.email.includes("student.uc.pt");
  };

  viewContact = (contact) => {
    if (contact.memberOf.includes("cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt")) {
      this.$state.go('app.contacts.view', { id: contact.id });
    }
  };
}

PersonListController.$inject = ['$state', '$scope', '$translate', 'PersonService', 'UIService', 'AuthenticationService'];
