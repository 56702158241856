export default class PersonService {
  constructor(Person, $q) {
    this.Person = Person;
    this.$q = $q;
  }

  types = () => {
    return [
      {
        id: 0,
        title: {
          pt: "Todos",
          en: "All",
        }
      }, {
        id: 1,
        title: {
          pt: "Docentes",
          en: "Teachers"
        }
      }, {
        id: 2,
        title: {
          pt: "Não Docentes",
          en: "Staff"
        }
      }, {
        id: 3,
        title: {
          pt: "ISR",
          en: "ISR"
        }
      }, {
        id: 4,
        title: {
          pt: "IT",
          en: "IT"
        }
      }, {
        id: 5,
        title: {
          pt: "INESC",
          en: "INESC"
        }
      }
    ]
  };

  all = () => {
    let defer = this.$q.defer();
    this.$q.all([this.teachers(), this.staffs(), this.isr(), this.it(), this.inesc()]).then(r => {
      defer.resolve({
        teachers: r[0],
        staff: r[1],
        isr: r[2],
        it: r[3],
        inesc: r[4],
        list: [].concat(r[0], r[1], r[2], r[3], r[4])
      });
    });
    return defer.promise;
  };

  students = () => {
    let defer = this.$q.defer();
    this.Person.findStudents().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  isr = () => {
    let defer = this.$q.defer();
    this.Person.findISR().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  it = () => {
    let defer = this.$q.defer();
    this.Person.findIT().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  inesc = () => {
    let defer = this.$q.defer();
    this.Person.findINESCC().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  staffs = () => {
    let defer = this.$q.defer();
    this.Person.findStaffs().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  student = (id) => {
    let defer = this.$q.defer();
    this.Person.findStudent({ id: id }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  staff = (id) => {
    let defer = this.$q.defer();
    this.Person.findStaff({ id: id }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  teachers = () => {
    let defer = this.$q.defer();
    this.Person.findTeachers().$promise.then(row => {
      defer.resolve(row)
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  teacher = (id) => {
    let defer = this.$q.defer();
    this.Person.findTeacher({ id: id }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };
}

PersonService.$inject = ['Person', '$q'];
