export default class JobService {
  constructor(
    Job,
    JobType,
    $q,
    AuthenticationService,
    Recycle,
    AuthorizationService
  ) {
    this.JobType = JobType;
    this.Auth = AuthenticationService;
    this.Authorize = AuthorizationService;
    this.Recycle = Recycle;
    this.Job = Job;
    this.$q = $q;
    this.zzb = [];
    this.types = [];
  }

  my = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    user.id = user.id || user.email;
    let filter = {
      where: {
        "inserted.id": user.id,
      },
      order: "id DESC",
    };
    if (user.memberOf.some((r) =>
      [
        //"cn=Jobs,cn=groups,dc=deec,dc=uc,dc=pt",
        //"cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt",
        //"cn=Funcionarios,cn=groups,dc=deec,dc=uc,dc=pt",
        "cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt",
      ].includes(r)
    )) {
      delete filter.where;
    }
    this.Job.find({
      filter: filter,
    })
      .$promise.then((r) => {
        defer.resolve({
          total: r.length,
          data: r,
        });
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  canInsert = () => {
    this.user = this.Auth.getUser();
    if (!this.user) return false;
    this.user.memberOf = this.user.memberOf || [];
    /* Can insert (groups):
     * Jobs
     * Funcionarios
     * Docentes
     * Informatica
     * Whitelisted persons locally
     */
    return (
      this.Authorize.isWhitelisted("app.jobs", this.user.email) ||
      this.user.memberOf.some((r) =>
        [
          "cn=Jobs,cn=groups,dc=deec,dc=uc,dc=pt",
          "cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt",
          "cn=Funcionarios,cn=groups,dc=deec,dc=uc,dc=pt",
          "cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt",
        ].includes(r)
      )
    );
  };

  suggestions = () => {
    let defer = this.$q.defer();
    /*
    if (this.zzb.length) {
      defer.resolve(this.zzb);
    }*/
    this.Job.find({
      filter: {
        where: {
          state: 1,
        },
      },
    })
      .$promise.then((r) => {
        let l = [];
        r.forEach((suggestion) => {
          let exists = l.find((line) => line.name == suggestion.entity.name);
          if (exists != undefined) {
            if (!exists.url) {
              exists.url = suggestion.entity.url;
            }
          } else {
            l.push(suggestion.entity);
          }
        });
        l = l.filter((r) => {
          let exclude = ["it", "isr", "uc", "ipn", "isr - coimbra", "e-redes"];
          let exists = exclude.find((e) => e == r.name.toLowerCase());
          if (!exists) {
            return r;
          }
        });
        l.sort((a, b) => a.name.localeCompare(b.name));
        this.zzb = l;
        defer.resolve(this.zzb);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  type = () => {
    let defer = this.$q.defer();
    if (this.types.length) {
      defer.resolve(this.types);
    } else {
      this.JobType.find()
        .$promise.then((r) => {
          this.types = r;
          defer.resolve(r);
        })
        .catch((e) => {
          defer.reject(e);
        });
    }
    return defer.promise;
  };

  list = (filter) => {
    // Only show valid
    filter.where.state = 1;
    let defer = this.$q.defer();
    this.Job.find({
      filter: filter,
    })
      .$promise.then((list) => {
        defer.resolve(list);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Job.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  isOwner = (data) => {
    let user = this.Auth.getUser();
    if (!user) {
      return false;
    }
    if (!data.inserted) {
      return false;
    }
    return (
      data.inserted.id === user.id ||
      user.memberOf.includes("cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt")
    );
  };

  save = (data) => {
    data.inserted = data.inserted || this.Auth.getUser();
    let aux = false;
    if (data.duration) {
      data.dateStart = null;
      data.dateEnd = null;
      aux = true;
      delete data.dateStart;
      delete data.dateEnd;
    }
    if (data.dateStart && data.dateEnd) {
      data.duration = null;
      delete data.duration;
    }
    let defer = this.$q.defer();
    let validation = this.validate(data, aux);
    if (validation.length == 0) {
      this.Job.upsert(data)
        .$promise.then((data) => {
          defer.resolve(data);
        })
        .catch((e) => {
          defer.reject(["Não foi possível guardar a proposta de emprego"]);
        });
    } else {
      defer.reject(validation.messages);
    }
    return defer.promise;
  };

  validate = (data) => {
    let messages = [];
    if (!data.title) messages.push("Insira um título");
    if (data.title && !data.title.pt) {
      messages.push("Insira um título em Português");
    }
    /*
    if (data.title && !data.title.en) {
      messages.push("Insira um título em Inglês");
    }*/

    if (!data.description) messages.push("Insira uma descrição");
    if (data.description && !data.description.pt) {
      messages.push("Insira uma descrição em Português");
    }
    /*
    if (data.description && !data.description.en) {
      messages.push("Insira uma descrição em Inglês");
    }
    */

    if (!data.entity) {
      messages.push("Insira/escolha uma entidade");
    }

    if (!data.type) {
      messages.push("Insira/escolha o tipo de proposta");
    }

    if (!data.expires) messages.push("Insira uma data de validade da proposta");

    return messages;
  };

  hide = (data) => {
    let defer = this.$q.defer();
    data.state = 0;
    data
      .$save()
      .then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  show = (data) => {
    let defer = this.$q.defer();
    data.state = 1;
    data
      .$save()
      .then((r) => {
        defer.resolve(r);
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  delete = (job) => {
    let defer = this.$q.defer();
    let u = this.Auth.getUser();
    this.Job.deleteById({
      id: job.id,
    })
      .$promise.then(() => {
        this.Recycle.create({
          model: "Job",
          userId: u.email,
          object: JSON.stringify(job),
        })
          .$promise.then(() => {
            defer.resolve();
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

JobService.$inject = [
  "Job",
  "Jobtype",
  "$q",
  "AuthenticationService",
  "Recycle",
  "AuthorizationService",
];
