import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
        },
        error: {
            main: "#a94442",
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
        },
    },
});
