export default class AccessRequestsController {
  constructor($state, AccessService, UIService) {
    this.$state = $state;
    this.AccessService = AccessService;
    this.UI = UIService;

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    let filter = {filter: {where: {state: 1}, order: 'dateStart ASC'}};
    this.AccessService.load(filter).then(list => {
      this.data = list;
      this.loaded = true;
    });
  };

  complete = (data) => {
    this.AccessService.setState(data.id, 3).then(() => {
      this.sendEmail(data); // send email to applicant to inform request was aproved a accesses given
      this.UI.addToast('Pedido Concluído');
      this.loadData();
    });
  };

  sendEmail = (data) => {
    let details = {
      emailAddress: data.applicantMail,
      subject: 'my.DEEC | Pedido de Acesso Aprovado',
      emailModel: 'AcessosCompletos',
      greeting: 'O seu pedido de acesso foi aprovado:',
      introText: 'Os seus novos acessos já se encontram atribuídos.',
      applicantNumber: data.applicantNumber,
      applicantName: data.applicantName,
      applicantMail: data.applicantMail,
      responsibleEntityMail: data.responsibleEntityMail,
      dateStart: moment(data.dateStart).format("DD/MM/YYYY"),
      dateEnd: moment(data.dateEnd).format("DD/MM/YYYY"),
      reason: data.reason,
      observations: data.observations,
      requestType: data.requestType,
      spaces: this.AccessService.processSpaces2(data)
    };

    this.AccessService.email(details).then(() => {
      // Do nothing here
    });
  };
}
AccessRequestsController.$inject = ['$state', 'AccessService', 'UIService'];
