export default class PublicDissertationController {
  constructor(DissertationService) {
    this.lang = "en";
    this.DissertationService = DissertationService;
    this.loadData();
  }

  loadData = () => {
    let filter = {
      where: {
        state: 1,
        student: {
          eq: null
        },
        "title.en": {
          neq: ""
        }
      }
    }
    this.DissertationService.list(filter).then(r => {
      let dissertations = [];
      r.data.forEach(r => {
        dissertations.push(...r.dissertations);
      });
      this.data = dissertations;
      this.loaded = true;
    });
  }
}

PublicDissertationController.$inject = ['DissertationService'];
