import React, { useEffect, useState } from "react";
import { FileBrowser, ChonkyActions, FileHelper, FileList, FileNavbar, FileToolbar, setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
setChonkyDefaults({ iconComponent: ChonkyIconFA, disableDragAndDrop: true, disableDefaultFileActions: true, clearSelectionOnOutsideClick: true, disableSelection: true });

export function MediaMaterial({ data }) {
  const fileMap = data.fileMap;
  const [currentFolderId, setCurrentFolderId] = useState(data.rootFolderId);
  const [files, setFiles] = useState([]);
  const [folderChain, setFolderChain] = useState([]);

  const useFiles = () => {
    const currentFolder = fileMap[currentFolderId];
    const files = currentFolder.childrenIds ? currentFolder.childrenIds.map((r) => fileMap[r]) : [];
    setFiles(files);
  };

  const useFolderChain = () => {
    const currentFolder = fileMap[currentFolderId];
    const folderChain = [currentFolder];
    let parentId = currentFolder.parentId;
    while (parentId) {
      const parentFile = fileMap[parentId];
      if (parentFile) {
        folderChain.unshift(parentFile);
        parentId = parentFile.parentId;
      } else {
        parentId = null;
      }
    }
    setFolderChain(folderChain);
  };

  const handleFileAction = (data) => {
    if (data.id == ChonkyActions.OpenFiles.id) {
      const { targetFile, files } = data.payload;
      const fileToOpen = targetFile || files[0];
      if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
        setCurrentFolderId(fileToOpen.id);
        return;
      } else {
        window.open(`/api/assets/explorer/${fileToOpen.id}`, "_blank");
      }
    }
  };

  useEffect(() => {
    useFolderChain();
    useFiles();
  }, [currentFolderId]);

  return (
    <FileBrowser files={files} folderChain={folderChain} onFileAction={handleFileAction}>
      <FileNavbar />
      <FileToolbar />
      <FileList />
    </FileBrowser>
  );
}
