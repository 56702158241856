export function routes($stateProvider) {
  $stateProvider
    .state('app.charges', {
      url: '/charges',
      abstract: true,
      template: '<ui-view></ui-view>',
    })
    .state('app.charges.list', {
      url: '/list',
      template: require('./list/view.html'),
      controller: 'ChargesListController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=Nao Docentes,cn=groups,dc=deec,dc=uc,dc=pt",'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.charges.insert', {
      url: '/insert',
      template: require('./insert/view.html'),
      controller: 'ChargesInsertController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=Nao Docentes,cn=groups,dc=deec,dc=uc,dc=pt",'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
    .state('app.charges.update', {
      url: '/update/{id:string}', 
      template: require('./update/view.html'),
      controller: 'ChargesUpdateController',
      controllerAs: 'vm',
      memberOf: ["cn=Docentes,cn=groups,dc=deec,dc=uc,dc=pt","cn=Nao Docentes,cn=groups,dc=deec,dc=uc,dc=pt",'cn=Informatica,cn=groups,dc=deec,dc=uc,dc=pt']
    })
  ;
}

routes.$inject = ['$stateProvider'];
