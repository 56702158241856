import {routes} from './routes';
import DissertationService from "./service";
import DissertationsListController from "./list/controller";
import DissertationsInsertController from "./insert/controller";
import DissertationsOwnController from "./own/controller";
import DissertationDetailsController from "./details/controller";
import DissertationsEditController from "./edit/controller";
import DissertationsAssignedController from "./assigned/controller";

export default angular.module('app.dissertations', [])
  .config(routes)
  .service('DissertationService', DissertationService)
  .controller('DissertationsListController', DissertationsListController)
  .controller('DissertationsOwnController', DissertationsOwnController)
  .controller('DissertationDetailsController', DissertationDetailsController)
  .controller('DissertationsEditController', DissertationsEditController)
  .controller('DissertationsInsertController', DissertationsInsertController)
  .controller('DissertationsAssignedController', DissertationsAssignedController)
  .name;
