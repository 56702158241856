import angular from 'angular';
import {routes} from './routes';
import PublicDissertationController from './dissertations/controller';
import PublicDissertationDetailsController from './dissertations/details/controller';
import PublicAccessFormController from "./accessform/controller";

export default angular.module('app.public', [])
  .config(routes)
  .controller('PublicDissertationController', PublicDissertationController)
  .controller('PublicDissertationDetailsController', PublicDissertationDetailsController)
  .controller('PublicAccessFormController', PublicAccessFormController)
  .name;
