import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import "./utils.css";
const SimpleModal = (props) => {
    const { open, onClose, message } = props;
    const handleClose = (result) => {
        onClose(result);
    };
    return (<Modal open={open} onClose={() => handleClose(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="simpleModal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {message}
        </Typography>
        <Stack direction="row" spacing={2} position="absolute" bottom="10px" right="10px">
          <Button sx={{ color: "black" }} onClick={() => handleClose(false)}>
            Cancelar
          </Button>
          <Button sx={{ color: "red" }} onClick={() => handleClose(true)}>
            Ok
          </Button>
        </Stack>
      </Box>
    </Modal>);
};
export default SimpleModal;
