export default class ProjectsOwnController {
  constructor($state, $scope, UIService, ProjectService) {
    this.$state = $state;
    this.UI = UIService;
    this.ProjectService = ProjectService;

    $scope.$watch(
      () => {
        return localStorage.getItem("lang");
      },
      (val) => {
        this.lang = val;
      },
      true
    );
  }
}

ProjectsOwnController.$inject = [
  "$state",
  "$scope",
  "UIService",
  "ProjectService",
];
