export default class RedirectController {
  constructor($window, $stateParams, $cookies, LoopBackAuth) {
    let token = $stateParams['access-token'];
    let userId = $stateParams['user-id'];
    if (angular.isDefined(token) && angular.isDefined(userId)) {
      LoopBackAuth.currentUserId = userId;
      LoopBackAuth.accessTokenId = token;
      LoopBackAuth.save();
      $window.opener.postMessage({
        type: 'oauth',
        result: true
      });
    } else {
      $window.opener.postMessage({
        type: 'oauth',
        result: false
      });
    }
    $window.close();
  }
}

RedirectController.$inject = ['$window', '$stateParams', '$cookies', 'LoopBackAuth'];
